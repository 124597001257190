import PropTypes from "prop-types";
import React from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import ReactModal from 'react-modal';
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter, 
    MotifFileUploader,
    MotifFormField
  } from "@ey-xd/motif-react";

const PeriodTbModeModal = ({ showModeModal, handleSubmit, handleCloseModeModal, initialValues }) => {
    let periodDescription = "Period";
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );
    //if (initialValues && initialValues.description) {
    //    periodDescription = initialValues.description;
    //}

    return (
        <MotifModal show={showModeModal} variant="alt" onClose={handleCloseModeModal}>
        <MotifModalHeader>                   
            <HeaderWrapper>
                <span className="ml-2">{periodDescription} Trial Balance Mode</span>
            </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>          
        <p>Please note changing trial balance mode will remove all trial balances and all system created securities for the period.</p>
        <div className="field">
                    <label><Field name="trialBalanceMode" component={formHelpers.renderField} type="radio" value="1" label="Use REIT & Property TBs"/></label><br />
                    <label><Field name="trialBalanceMode" component={formHelpers.renderField} type="radio" value="2" label="Use Consolidated Single Asset TB"/></label>
        </div>  
        </MotifModalBody>
        <MotifModalFooter>
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={handleCloseModeModal}>
                    Cancel
                </MotifButton>
                <MotifButton variant="primary" className="right ml-2" type="submit" onClick={handleSubmit}>
                    Submit
                </MotifButton>
        </MotifModalFooter>
    </MotifModal>


       
    );
};

PeriodTbModeModal.propTypes = {
    showModeModal: PropTypes.bool,
    handleCloseModeModal: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object
};

export default reduxForm({ form: "clientAuth", enableReinitialize: true })(PeriodTbModeModal);