import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Input, Message } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import RelatedReits from "../reits/RelatedReits";
import * as formatters from "../../scripts/formFormatters";
import * as parsers from "../../scripts/formParsers";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { PhoneField } from "../shared/formFields";
import ClientAuthList from "../../components/auth/ClientAuthList";
import * as authActions from "../../actions/authActions";
import { MotifButton, MotifToast, MotifFormField, MotifToggleSwitch } from '@ey-xd/motif-react';

const EditClientForm = ({
    reits,
    handleSubmit,
    handleAddReitClick,
    handleEditReitClick,
    handleDeleteReitClick,
    handleAddPropertyClick,
    handleEditPropertyClick,
    handleDeletePropertyClick,
    initialValues,
    clientFormPurpose,
    reitFormPurpose,
    showSuccess,
    showReitSuccess,
    error,
    submitting,
    auths, handleSearchChange, handleClearSearchText, clientUserSearchText, handleEditButtonClick, handleDeleteButtonClick,
    handleNewButtonClick, showAddClientAuthModal, handleAddClientAuthModalAction, auth, toggleShowAddClientAuthModal, currentUserId,
    showEditClientAuthModal, handleEditClientAuthModalAction, toggleShowEditClientAuthModal, currentUserIsSysAdmin, addAuthMessageText,maxLimitMessageText,
    handleDismissErrorMessage, handleDismissSuccessMessage, handleDismissReitSuccessMessage, handleToggleActiveFlag, isActive, isAudit, handleToggleAuditFlag,
    numberOfEngagementAdmins, handleEditClientCancel, dirty, validateDomain, validateDomainWithRole, clientAuthFormValues,
    handleAddClientAuthModalValidateAction, validatingUser, myEyError,handleDailyNewsletter,receiveDailyNewsletter,handleWeeklyNewsletter,receiveWeeklyNewsletter,
    handleRealTimeNotification,receiveRealTimeNotification, receiveRealTimeNotificationForNewPsqArqcSent,handleInternalReviewNotification,handleOnRoleTypeChange,handleGridChanges,
    isDataSteward, handleDataSteward, isAuthorizedUserSearchBoxVisible, dataStewardValue,dataStewardCheckboxDisplay,showDSError,buttonVarient
}) => {

    let operationMessage = "Client created successfully!";
    let reitOperationMessage = "REIT created successfully!";
    if (clientFormPurpose === "EDIT") {
        operationMessage = "Client updated successfully!";
    }
    if (reitFormPurpose === "EDIT") {
        reitOperationMessage = "REIT updated successfully!";
    }

    const client = Object.assign({}, initialValues);
    const toggleActiveFlag = (e) => {
        handleToggleActiveFlag(e, client.id, client.name, client.legalName);
    };

    return (
        
        // <form onSubmit={(e) => {e.preventDefault(); handleSubmit}}>
        <div>
            {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
            {showSuccess &&
                <MotifToast className="mb-3" variant="success" onClose={handleDismissSuccessMessage}>{operationMessage}</MotifToast>
            }
            <div className="row">

                <div className="col-md-6 field">
                    <Field name="legalName"
                        component={formHelpers.renderField}
                        type="text"
                        maxLength={50}
                        label="Full Legal Name (Required)"
                        validate={[formValidators.required, formValidators.minLength2, formValidators.maxLength50]}
                    />
                </div>

                <div className="col-md-3 field">
                    <Field
                        name="name"
                        component={formHelpers.renderField}
                        type="text"
                        maxLength={50}
                        label="Short Name (Required)"
                        validate={[formValidators.required, formValidators.minLength2, formValidators.maxLength50]}
                    />
                </div>

                <div className="col-md-3 d-flex">
                    <div>
                        <label aria-label={`Active toggle button ${isActive ? 'on' : 'off'}`}>Active: </label>
                        <div>
                            <MotifFormField aria-hidden="true">
                                <MotifToggleSwitch
                                    checked={isActive}
                                    disabled={!currentUserIsSysAdmin}
                                    onChange={toggleActiveFlag}
                                    id="isActiveToggle"
                                    name="isActive"
                                />
                            </MotifFormField>
                        </div>
                    </div>
                    <div className="ml-5">
                        <label aria-label={`Audit - ARQC Checklist Only toggle button ${isAudit ? 'on' : 'off'}`}>Audit - ARQC Checklist Only: </label>
                        <div>
                            <MotifFormField aria-hidden="true">
                                <MotifToggleSwitch
                                    checked={isAudit}
                                    disabled={!currentUserIsSysAdmin}
                                    onChange={handleToggleAuditFlag}
                                    id="isAuditToggle"
                                    name="isAudit"
                                />
                            </MotifFormField>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-6 field">
                    <Field
                        name="gfisCode"
                        component={formHelpers.renderField}
                        type="text"
                        maxLength={50}
                        label="GFIS Code"
                        disabled={!currentUserIsSysAdmin}
                        validate={[formValidators.maxLength50]}
                    />
                </div>

                <div className="col-md-6 field">
                    <Field
                        name="engagementNumber"
                        component={formHelpers.renderField}
                        type="text"
                        maxLength={50}
                        label="Client Engagement Number"
                        validate={[formValidators.maxLength50]} />
                </div>
            </div>

            <section className="client-contact-info">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Client Contact Information</h2>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4 field">
                        <Field name="contactName"
                            component={formHelpers.renderField}
                            type="text"
                            maxLength={50}
                            label="Contact Name"
                            validate={[formValidators.maxLength50]}
                        />
                    </div>
                    <div className="col-md-4 field">
                        <PhoneField name="contactPhone" label="Phone"
                        validate={[                            
                            formValidators.phoneNumber,
                        ]} />                        
                    </div>
                    <div className="col-md-4 field">
                        <Field
                            name="contactEmail"
                            component={formHelpers.renderField}
                            type="email"
                            label="Contact Email"
                            maxLength={255}
                            validate={[formValidators.email, formValidators.maxLength255]}
                        />
                    </div>
                </div>
                {(!(initialValues && initialValues.id) &&
                    <div className="row mt-3">
                        <div className="col-md-4 field">
                            <Field
                                name="engagementAdministratorEmail"
                                component={formHelpers.renderField}
                                type="email"
                                label="Engagement Administrator Email (Required)"
                                maxLength={255}
                                validate={[formValidators.email, validateDomain, formValidators.required, formValidators.maxLength255]}
                            />
                        </div>
                    </div>) || <div />}
            </section>
            
            {showReitSuccess && <MotifToast className="mt-2 mb-2" variant="success" onClose={handleDismissReitSuccessMessage}>{reitOperationMessage}</MotifToast>}
            {(initialValues &&
                initialValues.id &&
                <RelatedReits reits={reits} handleAddReitClick={handleAddReitClick} handleEditClick={handleEditReitClick} handleDeleteClick={handleDeleteReitClick} canAddReit={!dirty} />) ||
                <div />}
            <br />

            {(initialValues &&
                initialValues.id && <ClientAuthList
                    auths={auths}
                    handleSearchChange={handleSearchChange}
                    handleClearSearchText={handleClearSearchText}
                    clientUserSearchText={clientUserSearchText}
                    handleEditButtonClick={handleEditButtonClick}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                    handleNewButtonClick={handleNewButtonClick}
                    showAddClientAuthModal={showAddClientAuthModal}
                    handleAddClientAuthModalAction={handleAddClientAuthModalAction}
                    auth={auth} toggleShowAddClientAuthModal={toggleShowAddClientAuthModal}
                    currentUserId={currentUserId}
                    showEditClientAuthModal={showEditClientAuthModal}
                    handleEditClientAuthModalAction={handleEditClientAuthModalAction}
                    toggleShowEditClientAuthModal={toggleShowEditClientAuthModal}
                    currentUserIsSysAdmin={currentUserIsSysAdmin}
                    addAuthMessageText={addAuthMessageText}
                    maxLimitMessageText={maxLimitMessageText}
                    numberOfEngagementAdmins={numberOfEngagementAdmins}
                    validateDomainWithRole={validateDomainWithRole}
                    clientAuthFormValues={clientAuthFormValues}
                    handleAddClientAuthModalValidateAction={handleAddClientAuthModalValidateAction}
                    validatingUser={validatingUser}
                    myEyError={myEyError}
                    handleDailyNewsletter={handleDailyNewsletter}
                    receiveDailyNewsletter={receiveDailyNewsletter}
                    handleWeeklyNewsletter={handleWeeklyNewsletter}
                    receiveWeeklyNewsletter={receiveWeeklyNewsletter}
                    handleRealTimeNotification={handleRealTimeNotification}
                    receiveRealTimeNotification={receiveRealTimeNotification}
                    receiveRealTimeNotificationForNewPsqArqcSent= {receiveRealTimeNotificationForNewPsqArqcSent}
                    handleInternalReviewNotification= {handleInternalReviewNotification}
                    handleOnRoleTypeChange={handleOnRoleTypeChange}
                    handleGridChanges={handleGridChanges}
                    isDataSteward={isDataSteward}
                    handleDataSteward={handleDataSteward}
                    isAuthorizedUserSearchBoxVisible={isAuthorizedUserSearchBoxVisible}
                    dataStewardCheckboxDisplay={dataStewardCheckboxDisplay}
                    dataStewardValue={dataStewardValue}
                    showDSError={showDSError}
                    buttonVarient={buttonVarient}
                    parentComponent="Client"
                    />) || <div />}
            <br />
            <div className="row action mb-3">
                <br />
                <div className="col-md-12 container">  
                <div className="cancelSubmit">
                    <MotifButton variant="secondary" type="button" className="mr-2"
                        onClick={handleEditClientCancel} title="Cancel">
                        Cancel
                    </MotifButton> 
                    <MotifButton variant="primary"  type="button"
                        disabled={submitting} title="Submit client data"
                        onClick={handleSubmit}>
                        Submit
                    </MotifButton>
                    </div>
                </div>
            </div>
         </div>
    );
};

EditClientForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleAddReitClick: PropTypes.func.isRequired,
    handleEditClientCancel: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    reits: PropTypes.array,
    handleDeleteReitClick: PropTypes.func,
    handleAddPropertyClick: PropTypes.func,
    handleEditPropertyClick: PropTypes.func,
    handleDeletePropertyClick: PropTypes.func,
    clientFormPurpose: PropTypes.string,
    reitFormPurpose: PropTypes.string,
    showSuccess: PropTypes.bool,
    showReitSuccess: PropTypes.bool,
    auths: PropTypes.array,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    clientUserSearchText: PropTypes.string,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    handleNewButtonClick: PropTypes.func,
    showAddClientAuthModal: PropTypes.bool,
    handleAddClientAuthModalAction: PropTypes.func,
    auth: PropTypes.object,
    toggleShowAddClientAuthModal: PropTypes.func,
    currentUserId: PropTypes.string,
    showEditClientAuthModal: PropTypes.bool,
    handleEditClientAuthModalAction: PropTypes.func,
    toggleShowEditClientAuthModal: PropTypes.func,
    currentUserIsSysAdmin: PropTypes.bool,
    addAuthMessageText: PropTypes.string,
    maxLimitMessageText: PropTypes.string,
    handleDismissErrorMessage: PropTypes.func,
    handleDismissSuccessMessage: PropTypes.func,
    handleDismissReitSuccessMessage: PropTypes.func,
    handleToggleActiveFlag: PropTypes.func,
    isActive: PropTypes.bool,
    isAudit: PropTypes.bool,
    numberOfEngagementAdmins: PropTypes.number,
    dataStewardCheckboxDisplay: PropTypes.bool,
    dataStewardValue: PropTypes.bool,
    showDSError: PropTypes.bool,
    handleEditReitClick: PropTypes.func.isRequired,
    dirty: PropTypes.bool,
    validateDomain: PropTypes.func,
    clientAuthFormValues: PropTypes.object,
    handleAddClientAuthModalValidateAction: PropTypes.func,
    validatingUser: PropTypes.bool,
    myEyError: PropTypes.string,
    handleDailyNewsletter:PropTypes.func,
    receiveDailyNewsletter:PropTypes.bool,
    handleWeeklyNewsletter:PropTypes.func,
    receiveWeeklyNewsletter:PropTypes.bool,
    handleRealTimeNotification:PropTypes.func,
    receiveRealTimeNotification:PropTypes.bool,
    receiveRealTimeNotificationForNewPsqArqcSent: PropTypes.bool,
    handleInternalReviewNotification: PropTypes.func,
    isDataSteward:PropTypes.bool,
    handleDataSteward:PropTypes.func,
};

export default reduxForm({ form: "client", enableReinitialize: true })(EditClientForm);
