import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import * as constants from '../../constants/other';
import { useSelector } from 'react-redux';
import { MotifTabNavigation, MotifTabControl, MotifTextLink } from "@ey-xd/motif-react";
import { Link } from 'react-router-dom';

function DataRetentionManagementPolicy(props) {

    const params = useParams();
    const { clientId } = useParams();
    let dataPurgeConfigurationParameter_ARQC = null;
    let dataPurgeConfigurationParameter_Regular = null;
    let dataPurgeConfigurationParameter_ARQC_Value = null; // to set the default value while page loading
    let dataPurgeConfigurationParameter_Regular_Value = null; // to set the default value while page loading
    const convertWordToNum = num => ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"][num];

    const dataPurgeConfigurationValues = useSelector(
        state => state.dataPurgeConfigurationData
    );

    const isDecimal = (num) => {
        return num % 1 !== 0;
    }

    const [tabBarIndex, setTabBar] = useState(1);

    //Set current tab index
    const handleClick = (index) => {
        setTabBar(index);
    }

    dataPurgeConfigurationParameter_ARQC = dataPurgeConfigurationValues && dataPurgeConfigurationValues.find(item => item.dataPurgeConfigurationParameter === constants.DataPurgeConfigurationParameter_ARQC);

    dataPurgeConfigurationParameter_ARQC_Value = dataPurgeConfigurationParameter_ARQC ?
        isDecimal(dataPurgeConfigurationParameter_ARQC.dataPurgeConfigurationValue) ?
            dataPurgeConfigurationParameter_ARQC.dataPurgeConfigurationValue :
            convertWordToNum(dataPurgeConfigurationParameter_ARQC.dataPurgeConfigurationValue) : null;

    dataPurgeConfigurationParameter_Regular = dataPurgeConfigurationValues && dataPurgeConfigurationValues.find(item => item.dataPurgeConfigurationParameter === constants.DataPurgeConfigurationParameter_Regular) || null;
    dataPurgeConfigurationParameter_Regular_Value = dataPurgeConfigurationParameter_Regular ?
        isDecimal(dataPurgeConfigurationParameter_Regular.dataPurgeConfigurationValue) ? dataPurgeConfigurationParameter_Regular.dataPurgeConfigurationValue
            :
            convertWordToNum(dataPurgeConfigurationParameter_Regular.dataPurgeConfigurationValue) : null;

    return (<div>
        <h2>Help Guide</h2>
        <div className="row mt-4">
            <div className="field col-md-2">
                <MotifTabNavigation defaultActiveKey={0} id="helpGuideNav" orientation="vertical" lineSide="right" data-testid="helpGuide">
                    <MotifTabControl
                        className="d-flex align-items-center pt-2 pb-2 tabNavigation-hover"
                        style={{ background: "#FFF", marginBottom: "1px" }}
                        onClick={() => handleClick(1)}
                        key={0}>
                        <span className="helpGuideNavigation" data-testid="lblDataRetentionPolicyHelpGuide">Data Retention Policy</span>
                    </MotifTabControl>
                </MotifTabNavigation>
            </div>
            <div className="field col-md-10">
                {tabBarIndex === 1 &&
                    <div>
                        <h3 style={{ borderBottom: '1px solid #999999', lineHeight: '1.25em', paddingBottom: '23px' }}>REITSuite Data Retention Policy  </h3>
                        <br></br>
                        <ol>
                            <h4 id="overview"><li>Executive Summary</li></h4>
                            <p>
                                The objective of the REITSuite Data Retention Policy, which will be in effect from October 11, 2024, is to define the requirements for retaining data and to ensure the secure disposal of records and information within REITSuite once their retention period has lapsed, unless an exemption has been approved for extended retention.
                            </p>
                            <p>Adherence to the Policy mitigates risk to EY, our people, and our clients, of possible reputational or brand damage, reduced client confidence, or financial sanctions or penalties. It also:</p>
                            <ul>
                                <li>Protects the integrity and availability of business-critical records and information.</li>
                                <li>Establishes requirements for and evidence of conformity with laws and regulations for the retention, handling and the periodic review and disposition of eligible records and information.</li>
                                <li>Protects the integrity and availability of business-critical records and information. </li>
                                <li>Reduces operating and storage costs by decreasing the volume of unnecessary records and information.</li>
                            </ul>


                            <h4 id="overview"><li>Firm Standards and Exceptions</li></h4>
                            <ol type="a">
                                <h5><li>Firm Standards & REITSuite</li></h5>
                                <p>The firm defines data stored in REITSuite into different data classes with different data retention rules:</p>
                                <ul className='no-bullets'>
                                    <li><b>i.</b> <b>Required Records:</b> Includes client data, information, working papers, work products and/or final outputs/deliverables that document the service that has been provided to the client. Required records should be moved out of a Tool and into EYI-MyDocs (or Canvas) on a real time basis and/or within 30 days of the work covered by the engagement being completed. With respect to REITSuite, this includes all PBC, imports, checklists and reports, as appropriate.</li>
                                    <li><b>ii.</b> <b>Non-Records:</b> All other data, such as duplicates and expired data. With respect to REITSuite, this means we can retain the data in REITSuite (as a duplicate to what is saved in EYI-MyDocs or Canvas) as a secondary source up to two years. However, please note REITSuite has an exception approved by Tax Quality & Independence to save select data for five years or the life of the engagement in REITSuite. See Section 3 for a breakout on data retention windows per data type.</li>
                                </ul>

                                <h5><li>Exceptions</li></h5>
                                <p>To retain the records longer than the 2- or 5-year (as shown in section 3) retention period within REITSuite, an engagement team member can send a request to the REITSuite team by sending an email to <Link to="mailto:reitsuite@ey.com" rel="noopener noreferrer"><MotifTextLink variant='alt' href="mailto:reitsuite@ey.com">reitsuite@ey.com</MotifTextLink></Link>. Please note any exceptions to this policy will ultimately need a business case that is approved by Tax Quality and Independence.</p>
                            </ol>

                            <h4 id="overview"><li>Data Purge Process</li></h4>
                            <p>The data purge protocol for the REITSuite system is an annual procedure, set to occur on the Friday preceding the second Sunday in December each year. This protocol is tailored to comply with the distinct retention needs for various engagement types and categories of records/information, as outlined below.</p>
                            <table border="1" style={{width: '90%'}}>
                                <thead>
                                    <tr>
                                        <th rowspan="2">Record/Information Type</th>
                                        <th colspan="2" style={{ textAlign: 'center',  border: '1px solid #c4c4cd'}}>Engagement Type</th>
                                    </tr>
                                    <tr>
                                        <th style={{ border: '1px solid #c4c4cd' }}>Tax</th>
                                        <th style={{ border: '1px solid #c4c4cd' }}>Audit - ARQC Checklist Only</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Checklist Attachments</td>
                                        <td rowspan="4">Standard (2 years)</td>
                                        <td rowspan="4">Standard (2 years)</td>
                                    </tr>
                                    <tr>
                                        <td>Period File Management: General & Other File Uploads</td>
                                    </tr>
                                    <tr>
                                        <td>Bulk Report Package*</td>
                                    </tr>
                                    <tr>
                                        <td>User Uploaded Final Deliverable Reports</td>
                                    </tr>
                                    <tr>
                                        <td>Checklist Delivery Schedule</td>
                                        <td rowspan="2" style={{ whiteSpace: 'nowrap' }}> Standard - Extended (5 Years)</td>
                                        <td rowspan="2" style={{ whiteSpace: 'nowrap' }}>Standard (2 years)</td>
                                    </tr>

                                    <tr>
                                        <td>Report Period and All Report Period level data associated with the report periods (TB Data, Checklist, Checklist Responses, Asset Testing, Income Testing, Distributions, Securities, etc.)</td>
                                    </tr>
                                    <tr>
                                        <td>Client, Client Settings</td>
                                        <td rowspan="3" style={{ whiteSpace: 'nowrap' }}>Life of Engagement</td>
                                        <td rowspan="3" style={{ whiteSpace: 'nowrap' }}>Life of Engagement</td>
                                    </tr>
                                    <tr>
                                        <td>REITs & Files at REIT level</td>
                                    </tr>
                                    <tr>
                                        <td>Custom FDR Templates</td>
                                    </tr>
                                    <tr>
                                        <td>PSQ Checklist Templates</td>
                                        <td rowspan="2" style={{ whiteSpace: 'nowrap', backgroundColor: '#fff'}}>Life of Engagement</td>
                                        <td rowspan="2" style={{ whiteSpace: 'nowrap', backgroundColor: '#fff' }}>N/A</td>
                                    </tr>
                                    <tr>
                                        <td>Chart of Accounts</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br></br>
                            <p>For Example, a 5-year retention policy includes the current tax year plus four previous tax years. In December 2024, teams generally have concluded the delivery of the 2023 tax return. At this time, testing quarters that support the 2018 tax return and earlier would be deleted. (Retaining tax years 2023, 2022, 2021, 2020, and 2019). Note, in many cases, a portion of the 2024 data will be collected and processed in REITSuite as well but not all data will be in the system to support the 2024 tax return. </p>
                            <p>For all other records without a 5-year exception, in December 2024, testing quarters for 2022 and earlier would be deleted. This will leave engagement teams with 2023 testing quarters, and in many cases, 2024 testing quarters as well.</p>
                            <h4 id="overview" ><li>Appointing a Data Steward to Manage the Purge Process </li></h4>
                            <p>Each client is required to appoint at least one, but no more than two Data Stewards. The Data Stewards are responsible for approving test periods for deletion. The system is designed to send automated reminders at predetermined intervals to ensure Data Stewards provide their responses in a timely manner.</p>
                            <p>Any report periods and associated records that have been cleared for deletion will be removed from the system on the designated deletion date. Please note, Report packages are the only data type that will delete in REITSuite without Data Steward approval. These files will automatically delete from REITSuite based upon the scheduled deletion date as shown for each report package on the 'Bulk Report Management' page. REITSuite determines the deletion date for a report package based upon the oldest applicable period in the report package.</p>
                            <p>For <u>all</u> client sites (both Tax and Audit – ARQC Only), Data Stewards are expected to approve deletions before the initial 2-year retention period expires. Failure to approve the deletion within the 2-year period will result in escalation to Tax Quality and Engagement Partners. Note, for Tax clients, the Data Stewards’ approval will also approve the deletion for the data types with a 5-year retention period as well. <u><i>However, as best practice, engagement teams are expected to transfer REITSuite data to EYI-MyDocs (or Canvas) on a real time basis or within 30 days of the work covered by the engagement being completed. Immediately after the data is transferred, Data Stewards should ideally approve the data for deletion and not wait for the 2-year retention period to lapse</i></u>.</p>

                            <h4 id="overview" ><li>Other Notes in the Data Deletion Process</li></h4>
                            <ul className='no-bullets'>
                                <li><h5 id="overview" style={{ paddingBottom: '3px', marginLeft:'-20px' }}><span style={{ paddingRight: '10px'}}>a.</span>Assigning Data Stewards</h5>
                                    <p style={{marginLeft:'5px'}}>The Data Stewards possess the authority to manage data and approve data deletions. Each client must assign at least one but no more than two Data Stewards from their Engagement Administrators list. Ideally Data Stewards would be Senior Manager or PPMD for that engagement.</p>

                                    <p style={{marginLeft:'5px'}}>To assign a Data Steward, follow these steps:</p>
                                    <ol style={{marginLeft:'26px'}}>
                                        <li>Choose a client from the Dashboard.</li>
                                        <li>Navigate to the Client Management dropdown menu.</li>
                                        <li>Go to Client Settings and locate the Authorized Users section.</li>
                                        <li>Identify the Engagement Administrator you wish to designate as a Data Steward and click the corresponding Edit icon.</li>
                                        <li>In the pop-up modal, check the Data Steward checkbox.</li>
                                        <li>Submit the changes to finalize the assignment.</li>
                                    </ol>
                                    <span style={{marginLeft: '-30px'}}>
                                            <img src="/images/dataRetentionPolicy/AssignDataStewards.png" alt="Step-by-Step Guide Screenshot" />
                                            </span>
                                </li>
                                <li><h5 id="overview" style={{ paddingBottom: '3px' }}><span style={{ paddingRight: '10px', marginLeft: '-20px' }}>b.</span>Unlocking Client Pages</h5>
                                    <span style={{ marginLeft: '6px'}}>
                                    <p>To ensure the prompt processing of data deletion requests, access to client pages on REITSuite will be suspended until a Data Steward is actively designated. Additionally, the system will automatically revoke the Data Steward designation when an individual leaves the firm. In the absence of an active Data Steward, the client page will be automatically locked by the system until a new Data Steward takes over the role. <b><u>All EY personnel linked to a REITSuite Client will be sent email reminders on the first Monday of every month until a Data Steward is allocated to that client.</u></b> To prevent your client site from being locked and to stop receiving these notifications, please assign a Data Steward without delay.</p>
                                    <p>For initial rollout the client site will not be locked until Monday, December 2<sup>nd</sup>, 2024 (the first Monday of the month). All clients created after this date will be locked upon client creation until Data Steward is assigned.</p>
                                </span>
                                </li>

                                <li><h5 id="overview" style={{ paddingBottom: '3px' }}><span style={{ paddingRight: '10px',marginLeft: '-20px' }}>c.</span>To 'Approve Data Deletion'</h5>
                                <span style={{ marginLeft: '6px'}}>
                                    <p>As a Data Steward, your responsibility is to confirm the REITSuite data has been moved to the permanent system of record within the appropriate period. After all required data has been saved outside of REITSuite, the Data Steward is required to approve the appropriate periods for deletion.</p>
                                    <p>To approve data deletions, follow these steps:</p>
                                   </span>
                                    <ol class="steps" style={{ marginLeft: '30px'}}>
                                        <li class="step">Choose a client from the list.</li>
                                        <li class="step">Navigate to the Client Management dropdown menu.</li>
                                        <li class="step">Go to Data Retention Management Page.</li>
                                        <li class="step">In the data grid, identify and select the REITs for which you are granting approval.</li>
                                        <li class="step">Click the Actions button.</li>
                                        <li class="step">From the options presented, click on ‘Approve Data Deletion’ to finalize, and submit your approvals.</li>
                                    </ol>
                                    <img style={{marginLeft: '-25px'}} src='/images/dataRetentionPolicy/ToApproveDataDeletion.png' alt="Step-by-Step Guide Screenshot" />
                                </li>

                                <li><h5 id="overview" style={{ paddingBottom: '3px' }}><span style={{ paddingRight: '10px',marginLeft: '-20px' }}>d.</span>To 'Unapprove Data Deletion' </h5>
                                <span style={{ marginLeft: '6px'}}>
                                    <p>To the extent you are not ready for the data to be deleted, as a Data Steward you can revoke previously granted approval (assuming its still before the Deletion Approval Due Date).</p>
                                    <p>To revoke previously approved data deletions, follow these steps:</p>
                                </span>
                                    <ol class="steps" style={{ marginLeft: '30px'}}>
                                        <li class="step">Choose a client from the list.</li>
                                        <li class="step">Navigate to the Client Management dropdown menu.</li>
                                        <li class="step">Go to Data Retention Management Page.</li>
                                        <li class="step">In the data grid, identify and select the REITs for which you have previously granted approval for the data deletion.</li>
                                        <li class="step">Click the Actions button.</li>
                                        <li class="step">From the options presented, click on ‘Unapprove Data Deletion’ to finalize, and submit your changes.</li>
                                    </ol>
                                    <img style={{marginLeft: '-30px'}} src='/images/dataRetentionPolicy/ToUnapproveDataDeletion.png' alt="Step-by-Step Guide Screenshot" />
                                </li>

                                <li><h5 id="overview" style={{ paddingBottom: '3px' }}><span style={{ paddingRight: '10px',marginLeft: '-20px'  }}>e.</span>Revoking Exceptions</h5>
                                <span style={{ marginLeft: '6px'}}>
                                    <p>When you no longer need an exception to hold the data beyond the 2-5 years window, as a Data Steward you could revoke previously approved exceptions for the data deletion. Note, only a REITSuite administrator can approve an exception to deletion after receiving confirmation from both the engagement team, Tax Quality and Independence. However, a Data Steward can remove the exception to deletion at any time.</p>
                                    <p>To revoke a previously approved exception for the data deletions, follow these steps:</p>
                                    </span>
                                    <ol class="steps" style={{ marginLeft: '30px'}}>
                                        <li class="step">Choose a client from the list.</li>
                                        <li class="step">Navigate to the Client Management dropdown menu.</li>
                                        <li class="step">Go to Data Retention Management Page.</li>
                                        <li class="step">In the data grid, identify and select the REITs for which the exception was previously approved.</li>
                                        <li class="step">Click the Actions button.</li>
                                        <li class="step">From the options presented, click on ‘Remove Exception to Deletion’ to finalize, and submit your changes.</li>
                                    </ol>
                                    <img style={{marginLeft: '-30px'}} src='/images/dataRetentionPolicy/ToRevokeException.png' alt="Step-by-Step Guide Screenshot" />
                                </li>
                                <li><h5 id="overview" style={{ paddingBottom: '3px' }}><span style={{ paddingRight: '10px' ,marginLeft: '-16px' }}>f.</span>Receiving Notification & Alerts</h5>
                                    <p style={{ marginLeft: '6px'}}>Every EY user associated with a REITSuite Client will be sent email notifications regarding essential data stewardship responsibilities.</p>

                                    <ul style={{ marginLeft: '30px'}}><li>EY users will be notified via email at intervals of 90, 60, 30, 14, and then daily from 7 days down to 1 day before the scheduled annual data deletion date, set for the Friday preceding the second Sunday in December. Should this date fall on a Friday to following Monday, the notification will be issued on the following Tuesday.</li>
                                        <li>Should the deletion date elapse with approvals or exceptions still outstanding, daily email reminders will persist until these matters are addressed.</li>
                                    </ul>
                                </li>


                                <li><h5 id="overview" style={{ paddingBottom: '3px' }}><span style={{ paddingRight: '10px' ,marginLeft: '-20px' }}>g.</span>Exporting Data Deletion Summary</h5>
                                <span style={{ marginLeft: '6px'}}>
                                    <p>For record-keeping and effective data management, the Data Retention Management Page includes an "Export Data Deletion Summary" feature. This functionality enables users to download a detailed record of data slated for deletion, ensuring transparency and traceability in the data management process.</p>
                                    <p>The exported file contains several columns for easy review and verification: REIT, Report Period, Deletion Approval Due Date, Scheduled Deletion Date, Actual Deletion Date, Deletion Status, Approved By, and Approved Date. This structured format allows users to clearly understand and document the lifecycle of data within the system, from the scheduled deletion timeline to the actual execution and approval of the data removal.</p>

                                    <p>To export data deletion summary, follow these steps:</p>
                                    </span>
                                    <ol class="steps" style={{ marginLeft: '30px'}}>
                                        <li class="step">Choose a client from the list.</li>
                                        <li class="step">Navigate to the Client Management dropdown menu.</li>
                                        <li class="step">Go to Data Retention Management Page.</li>
                                        <li class="step">Click the Actions button.</li>
                                        <li class="step">From the options presented, click the ‘Export Data Deletion Summary’.</li>
                                    </ol>
                                    <img style={{marginLeft: '-30px'}} src='/images/dataRetentionPolicy/ExportDataSummary.png' alt="Step-by-Step Guide Screenshot" />
                                </li>

                                <li><h5 id="overview" style={{ paddingBottom: '3px' }}><span style={{ paddingRight: '10px',marginLeft: '-20px' }}>h.</span>Report Period Creation Restrictions</h5>
                                    <p style={{ marginLeft: '6px'}}>Our policy restricts creating report periods beyond one full calendar year prior to the current calendar year. As an example, on January 1st, 2024, the system will only allow the creation of report periods going back to the first quarter of 2023 at the earliest. This restriction ensures that the system's data remains relevant and manageable, aligning with our commitment to efficient data stewardship and compliance with retention policies.</p>

                                </li>
                                <li><h5 id="overview" style={{ paddingBottom: '3px' }}><span style={{ paddingRight: '10px',marginLeft: '-16px' }}>i.</span>External User Data Access Restrictions</h5>
                                    <span style={{ marginLeft: '6px'}}>
                                    <p>External users can access checklist data from only the last two years for both Tax Engagements and Audit – ARQC Checklist Only Engagements. </p>
                                    <p>For example, following the 2024 deletion event in December, external users will have access to Checklists from 2023 and 2024. Note, for Tax Engagements, EY Professionals will be able to see more data than the external users.</p>
                                </span>
                                </li>

                            </ul>

                            <h4 id="overview"><li>EY Roles in the Data Retention Management Process</li></h4>

                            <ul className='no-bullets'><li><h5 id="overview" style={{ paddingBottom: '3px' ,marginLeft: '-21px'}}><span style={{ paddingRight: '10px' }}>a.</span>Roles and Responsibilities</h5>

                                <table border="1" style={{width: '90%'}}>
                                    <tr>
                                        <th style={{ border: '1px solid #c4c4cd' }}>Role</th>
                                        <th style={{ border: '1px solid #c4c4cd' }}>Responsibility</th>
                                    </tr>
                                    <tr>
                                        <td>Data Steward</td>
                                        <td>
                                            <ul>
                                                <li>Approve Data Deletion Requests</li>
                                                <li>Revoke, previously approved data deletion requests</li>
                                                <li>Raise exception requests to REITSuite System Administrators</li>
                                                <li>Revoke, previously granted exceptions</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Engagement Admin</td>
                                        <td>
                                            <ul>
                                                <li>Assign Data Steward</li>
                                                <li>Coordinate with Data Steward to ensure timely approval of data deletion requests</li>
                                            </ul>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Engagement User</td>
                                        <td>
                                            <ul>
                                                <li>Coordinate with Data Steward to ensure timely approval of data deletion requests</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>System Administrator</td>
                                        <td>
                                            <ul>
                                                <li>Approve or deny data deletion exception requests</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>External Client User</td>
                                        <td><ul><li>None</li></ul></td>
                                    </tr>
                                </table>

                            </li>
                            </ul>

                            <h4 id="overview"><li>Contact Information</li></h4>

                            <p>For inquiries about the REITSuite data retention policy or to request an exception to data deletion, please email <Link  target="_blank" rel="noopener noreferrer"><MotifTextLink  href="mailto:reitsuite@ey.com" target="_blank" variant='alt'>reitsuite@ey.com</MotifTextLink></Link>.
                            </p>  
                        </ol>
                    </div>
                }
            </div>
        </div>
    </div>

    );
}


export default DataRetentionManagementPolicy;



