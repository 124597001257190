import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Header, Icon } from "semantic-ui-react";
import { Field, reduxForm, SubmissionError } from "redux-form";

import { ReactMultiEmail } from "react-multi-email";
import * as formValidators from "../../scripts/formValidators";
import * as formHelpers from "../../scripts/formHelpers";
import $ from "jquery";
import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifProgressLoader,
} from "@ey-xd/motif-react";

const ForwardPsqForInternalReviewModal = ({
  handleSubmit,
  handleCancel,
  showModal,
  submitting,
  domRef,
  handleInternalReviewEmailFieldChanged,
  error,
  handleDismissErrorMessage,
  loaded,
  emails,
  isInititiallySetEmailsFrwd,
  setInititiallySetEmailsFrwd
}) => {
  const gridRef = useRef();
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );
  const validateEmails = () => {
    let internalReviewEmailsValue = $("#internalReviewEmails :input").val();

    if (internalReviewEmailsValue !== "") {
      throw new SubmissionError({
        _error:
          "'" + internalReviewEmailsValue + "' is not a valid email address.",
      });
    }

    return handleSubmit();
  };

  useEffect(() => {     
    if(isInititiallySetEmailsFrwd)   {
      domRef(gridRef ? gridRef : null);
    }
    else{
      setInititiallySetEmailsFrwd();
    }
    
}, [emails]);

  return (
    <MotifModal
      variant="alt"
      size="sm"
      aria-label="Submit Internally"
      show={showModal}
      data-testid="ForwardPSQForInternalReview"
      onClose={handleCancel}
    >
      <MotifModalHeader>
        <HeaderWrapper>
          <span className="ml-2">Submit Internally</span>
        </HeaderWrapper>
      </MotifModalHeader>

      <form onSubmit={handleSubmit(validateEmails)}>
        {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
        <div style={{ display: (loaded && "block") || "none" }}>
          <MotifModalBody>
            <div>
              <Field
                label="Send to (Multiple email addresses are supported if separated by a semi-colon)"
                component={formHelpers.renderReactMultiEmail}
                id="internalReviewEmails"
                name="internalReviewEmails"
                ref={domRef}
                handleChange={handleInternalReviewEmailFieldChanged}
                emails={emails}
                validate={[
                  formValidators.multipleEmails,
                  formValidators.maxLength255,
                ]}
              />
            </div>
          </MotifModalBody>
          <MotifModalFooter>
            <div>
              <MotifButton
                variant="secondary"
                className="right internal-review-modal-button"
                type="button"
                onClick={handleCancel}
                disabled={submitting}
              >
                Cancel
              </MotifButton>
              <MotifButton
                variant="primary"
                className="right internal-review-modal-button"
                type="submit"
                disabled={submitting || !emails.length}
              >
                Send
              </MotifButton>
            </div>
          </MotifModalFooter>
        </div>

        <div style={{ display: (loaded && "none") || "block", marginLeft:'10px'}}>
          <MotifProgressLoader
            show
            size="xs"
            tip="Loading..."
            variant="circular"
          />
        </div>
      </form>
    </MotifModal>
  );
};

ForwardPsqForInternalReviewModal.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  showModal: PropTypes.bool,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object,
  domRef: PropTypes.func,
  handleInternalReviewEmailFieldChanged: PropTypes.func,
  error: PropTypes.string,
  handleDismissErrorMessage: PropTypes.func,
  loaded: PropTypes.bool,
  emails: PropTypes.array,
  setInititiallySetEmailsFrwd: PropTypes.func,
};

export default reduxForm({
  form: "forwardPsqForInternalReview",
  enableReinitialize: true,
})(ForwardPsqForInternalReviewModal);
