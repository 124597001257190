export const checklistAttachmentsAcceptedTypes={
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "application/pdf": [".pdf"],
    "image/jpeg": [".jpg"],
    "image/gif": [".gif"],
    "image/bmp": [".bmp"],
    "image/png": [".png"],
    "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
    "text/csv": [".csv"],
    "application/xml": [".xml"],
    "message/rfc822": [".mht", ".mhtml"],
    "text/html": [".htm", ".html"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template": [".xltx"],
    "application/vnd.ms-excel.template.macroEnabled.12": [".xlt"],
    "text/plain": [".txt"],
    "application/prs.plucker": [".prn"],
    "application/x-dif": [".dif"],
    "application/vnd.ms-excel.slk": [".slk"],
    "application/vnd.ms-excel.addin.macroEnabled.12": [".xlam"],
    "application/vnd.ms-excel.addin.macroEnabled": [".xla"],
    "application/vnd.ms-xpsdocument": [".xps"],
    "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
    "application/vnd.ms-outlook": [".msg"],
  }
  
  // Convert the fileTypes object into a Set of accepted MIME types for easier checking
export const getAcceptedMimeTypes = (checklistAttachmentsAcceptedTypes) => {
    return new Set(
      Object.keys(checklistAttachmentsAcceptedTypes).flatMap(type => checklistAttachmentsAcceptedTypes[type].map(ext => `${type}`))
    );
  };

  export const validateFiles = (acceptedFiles, acceptedMimeTypes) => {
    // Filter out files that do not have an accepted MIME type
    const { validFiles, invalidFiles } = acceptedFiles.reduce(
      (acc, file) => {
        if (acceptedMimeTypes.has(file.type)) {
          acc.validFiles.push(file);
        } else {
          acc.invalidFiles.push(file);
        }
        return acc;
      },
      { validFiles: [], invalidFiles: [] }
    );
  
    return { validFiles, invalidFiles };
  };