import React from 'react';
import { MotifTooltip } from '@ey-xd/motif-react';
export default (props) => {
  let fieldValue = "";
  if(props.fieldName == 'internalRecipients') {
    fieldValue = props.node.data['internalRecipients'];
  }
    if (fieldValue && fieldValue.length > 30 && props.fieldName == 'internalRecipients') {
      return (
        <div>
          <span>{fieldValue.substring(0, 29)}</span>
          <span className="ml-1" style={{ cursor: 'pointer' }}>
            <MotifTooltip
              id="show-comments-tooltip"
              allowClick
              allowHover
              placement="bottom"
              trigger={<span style={{ whiteSpace: 'nowrap' }}>...</span>}
              variant="alt"
              style={{ maxWidth: '20px' }}
              hideCloseButton={true}
            >
              {fieldValue}
            </MotifTooltip>
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span>{fieldValue}</span>
        </div>
      );
    }
  }
