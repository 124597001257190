import { pendingTask, begin, end } from '../constants/pendingTask';
import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';
import { download } from '../scripts/fileHelpers';
/**
 * Saves the checklist delivery calendar records. If error occurs, it will dispatch the SAVE_CHECKLIST_DELIVERY_SCHEDULE_FAILURE action.
 * @param {array} cdc The array of ChecklistDeliveryCalendar records to update.
 * @returns {function} A function that returns a Promise.
 */
export function saveChecklistDeliveryCalendar(
  cdc
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(`/checklists/schedule`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(cdc)
    })
      .then(response => {
        return response.text();
      })
      .then(response => {
        const jsonResponse = response ? JSON.parse(response) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.SAVE_CHECKLIST_DELIVERY_SCHEDULE_FAILURE,
            jsonResponse,
          );
        }

        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.SAVE_CHECKLIST_DELIVERY_SCHEDULE_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch all the files attached to any checklist belonging to the specified period. If successful this will dispatch the LOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS
 * action, otherwise it will dispatch the LOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} calendarYear The calendar year of the checklist delivery calendar.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function fetchChecklistDeliveryCalendar(
  clientId,
  calendarYear
) {
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS,
        checklistDeliveryCalendar: []
      });
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(`/checklists/schedule/${clientId}/${calendarYear}`)
      .then(response => {
        return response.json();
      })
      .then(checklistDeliveryCalendar => {
        if (actionHelpers.isErrorResponse(checklistDeliveryCalendar)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE,
            checklistDeliveryCalendar,
          );
        }
        dispatch({
          type: actions.LOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS,
          checklistDeliveryCalendar,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Reset the files attached to any checklist belonging to the specified period. If successful this will dispatch the LOAD_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS
 * action, otherwise it will dispatch the LOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} calendarYear The calendar year of the checklist delivery calendar.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function resetChecklistDeliveryCalendar(
  clientId,
  calendarYear,
  psqArqc
) {
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.RESET_CHECKLIST_DELIVERY_SCHEDULE_FAILURE,
        checklistDeliveryCalendar: []
      });
    }

    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(`/checklists/schedule/${clientId}/${calendarYear}/${psqArqc}`, {
      method: 'DELETE'
    })
      .then(response => {
        return response;
      })
      .then(checklistDeliveryCalendar => {
        if (actionHelpers.isErrorResponse(checklistDeliveryCalendar)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.RESET_CHECKLIST_DELIVERY_SCHEDULE_FAILURE,
            checklistDeliveryCalendar,
          );
        }
        dispatch({
          type: actions.RESET_CHECKLIST_DELIVERY_SCHEDULE_SUCCESS,
          checklistDeliveryCalendar,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.RESET_CHECKLIST_DELIVERY_SCHEDULE_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch all the files attached to any checklist belonging to the specified period. If successful this will dispatch the LOAD_CHECKLIST_FILES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CHECKLIST_FILES_FAILURE action.
 * @param {number} periodId The id of the period.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function fetchChecklistFilesByPeriod(
  periodId,
  authHeader
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    dispatch({
      type: actions.LOAD_CHECKLIST_FILES_SUCCESS,
      checklistFiles: [],
    });
    return fetch(`/checklists/checklistFiles/${periodId}`, {
      headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
    })
      .then(response => {
        return response.json();
      })
      .then(checklistFiles => {
        if (actionHelpers.isErrorResponse(checklistFiles)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CHECKLIST_FILES_FAILURE,
            checklistFiles,
          );
        }

        dispatch({
          type: actions.LOAD_CHECKLIST_FILES_SUCCESS,
          checklistFiles,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CHECKLIST_FILES_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch all the files attached to a checklist. If successful this will dispatch the LOAD_CHECKLIST_FILES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CHECKLIST_FILES_FAILURE action.
 * @param {number} checklistId The id of the checklist.
 * @param {string} checklistType The checklist type (PSQ, ARQC).
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function fetchChecklistFiles(
  checklistId,
  checklistType,
  authHeader
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    dispatch({
      type: actions.LOAD_CHECKLIST_FILES_SUCCESS,
      checklistFiles: [],
    });
    return fetch(`/checklists/checklistFiles/${checklistType}/${checklistId}`, {
      headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
    })
      .then(response => {
        return response.json();
      })
      .then(checklistFiles => {
        if (actionHelpers.isErrorResponse(checklistFiles)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CHECKLIST_FILES_FAILURE,
            checklistFiles,
          );
        }

        dispatch({
          type: actions.LOAD_CHECKLIST_FILES_SUCCESS,
          checklistFiles,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CHECKLIST_FILES_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Upload file(s) related to checklist question. If all files successful, this will dispatch the CHECKLIST_FILES_UPLOADED_SUCCESS
 * action, otherwise it will dispatch the CHECKLIST_FILES_UPLOADED_FAILURE action.
 * @param {Array} files The array of files to upload.
 * @param {number} checklistId The ID of the checklist.
 * @param {string} checklistType The type of the checklist.
 * @param {number} questionId The ID of the question.
 * @param {string} guid The external user guid
 * @returns {function} A function that returns a Promise.
 */
export function uploadChecklistFiles(
  files,
  checklistId,
  checklistType,
  questionId,
  guid,
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const authHeader = guid ? actionHelpers.buildAuthHeader(guid, checklistType) : null;

    // Function to read and upload file, returning a Promise to indicate if complete
    const uploadFile = (file) => new Promise((resolve) => {
      let formData = new FormData();
      formData.append('file', file);
      formData.append('checklistId', checklistId);
      formData.append('checklistType', checklistType);
      formData.append('questionId', questionId);

      return fetch(`/checklists/uploadChecklistFile`, {
        headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
        method: 'POST',
        body: formData
      })
        .then(response => {
          if (response.status == 201) {
            return false;
          }
          return response.json();
        })
        .then(response => {
          if (actionHelpers.isErrorResponse(response)) {
            return actionHelpers.dispatchErrorAndEndTask(
              dispatch,
              actions.SAVE_COMMENT_FAILURE,
              response
            );
          }
          if (!response) {
            // If successful response, return true
            return resolve(true);
          }

          return resolve(false);
        }).catch(() => resolve(false));
    });

    const tasks = [];

    for (let x = 0; x < files.length; x++) {
      tasks.push(uploadFile(files[x]));
    }

    // When all the tasks to upload file(s) are complete,
    // re-fetch the checklist files,
    // then return a Promise containing the array of responses
    // which will have true/false for each file
    // indicating if each was successfully uploaded or not
    return Promise.all(tasks).then(responses => {
      dispatch({ type: actions.TASK_END, [pendingTask]: end });
      return responses;
    });
  };
}

/**
 * Fetch all ARQC checklist records belonging to the reit period. If successful this will dispatch the LOAD_ARQC_CHECKLIST_SUCCESS
 * action, otherwise it will dispatch the LOAD_ARQC_CHECKLIST_FAILURE action.
 * @param {number} periodId The id of the period that owns the checklist.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @param {number} lastSelectedChecklistId The ID of the last selected checklist.
 * @returns {function} A function that returns a Promise.
 */
export function fetchArqcChecklistsByPeriod(
  periodId,
  authHeader,
  lastSelectedChecklistId,
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    dispatch({
      type: actions.LOAD_PREVIOUS_PSQ_CHECKLIST_SUCCESS,
      psqChecklist: {},
    });
    return fetch(`/checklists/summaries?checklistTypeID=ARQC&reportPeriodID=${periodId}`, {
      headers:
        (authHeader && new Headers({ Authorization: authHeader })) || null,
    })
      .then(response => {
        return response.json();
      })
      .then(arqcChecklists => {
        if (actionHelpers.isErrorResponse(arqcChecklists)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_ARQC_CHECKLIST_FAILURE,
            arqcChecklists,
          );
        }

        dispatch({
          type: actions.LOAD_ARQC_CHECKLIST_SUCCESS,
          arqcChecklists,
          [pendingTask]: end,
        });
        if (arqcChecklists.length > 0) {
          if (
            lastSelectedChecklistId &&
            arqcChecklists.some(x => x.checklistID === lastSelectedChecklistId)
          ) {
            fetchArqcChecklistById(lastSelectedChecklistId)(dispatch);
          } else {
            fetchArqcChecklistById(arqcChecklists[0].checklistID)(dispatch);
          }
        }
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_ARQC_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch all ARQC checklist records belonging to the reit period. If successful this will dispatch the LOAD_ARQC_CHECKLIST_SUCCESS
 * action, otherwise it will dispatch the LOAD_ARQC_CHECKLIST_FAILURE action.
 * @param {number} periodId The id of the period that owns the checklist.
 * @returns {function} A function that returns a Promise.
 */
export function fetchHasIncompleteCheckistByPeriod(periodId) {
  return fetch(`/checklists/incomplete?periodId=${periodId}`)
    .then(response => {
      if (response.ok) return response.json();

      throw new Error(response.statusText);
    })
    .catch(error => {
      throw error;
    });
}

/**
 * Fetch all PSQ checklist records belonging to the reit period. If successful this will dispatch the LOAD_PSQ_CHECKLIST_SUCCESS
 * action, otherwise it will dispatch the LOAD_PSQ_CHECKLIST_FAILURE action.
 * @param {number} periodId The id of the period that owns the checklist.
 * @returns {function} A function that returns a Promise.
 */
export function fetchPsqChecklistSummariesByPeriod(periodId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/summaries?checklistTypeID=PSQ&reportPeriodID=${periodId}`)
      .then(response => {
        return response.json();
      })
      .then(psqChecklists => {
        if (actionHelpers.isErrorResponse(psqChecklists)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_PSQ_CHECKLIST_FAILURE,
            psqChecklists,
          );
        }

        dispatch({
          type: actions.LOAD_PSQ_CHECKLIST_SUCCESS,
          psqChecklists,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_PSQ_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch all ARQC checklist records belonging to the reit period. If successful this will dispatch the LOAD_ARQC_CHECKLIST_SUCCESS
 * action, otherwise it will dispatch the LOAD_ARQC_CHECKLIST_FAILURE action.
 * @param {number} periodId The id of the period that owns the checklist.
 * @returns {function} A function that returns a Promise.
 */
export function fetchArqcChecklistSummariesByPeriod(periodId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/summaries?checklistTypeID=ARQC&reportPeriodID=${periodId}`)
      .then(response => {
        return response.json();
      })
      .then(arqcChecklists => {
        if (actionHelpers.isErrorResponse(arqcChecklists)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_ARQC_CHECKLIST_FAILURE,
            arqcChecklists,
          );
        }

        dispatch({
          type: actions.LOAD_ARQC_CHECKLIST_SUCCESS,
          arqcChecklists,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_ARQC_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch all PSQ checklist records belonging to the reit period. If successful this will dispatch the LOAD_PSQ_CHECKLIST_SUCCESS
 * action, otherwise it will dispatch the LOAD_PSQ_CHECKLIST_FAILURE action.
 * @param {number} periodId The id of the period that owns the checklist.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @param {number} lastSelectedChecklistId The ID of the last selected checklist.
 * @returns {function} A function that returns a Promise.
 */
export function fetchPsqChecklistsByPeriod(
  periodId,
  authHeader,
  lastSelectedChecklistId,
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/summaries?checklistTypeID=PSQ&reportPeriodID=${periodId}`, {
      headers:
        (authHeader && new Headers({ Authorization: authHeader })) || null,
    })
      .then(response => {
        return response.json();
      })
      .then(psqChecklists => {
        if (actionHelpers.isErrorResponse(psqChecklists)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_PSQ_CHECKLIST_FAILURE,
            psqChecklists,
          );
        }

        dispatch({
          type: actions.LOAD_PSQ_CHECKLIST_SUCCESS,
          psqChecklists,
          [pendingTask]: end,
        });
        if (psqChecklists.length > 0) {
          if (
            lastSelectedChecklistId &&
            psqChecklists.some(x => x.checklistID === lastSelectedChecklistId)
          ) {
            fetchPsqChecklistById(lastSelectedChecklistId, true)(dispatch);
          } else {
            fetchPsqChecklistById(psqChecklists[0].checklistID, true)(dispatch);
          }
        }
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_PSQ_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch the ARQC checklist record with the specified ID. If unsuccessful this will dispatch the LOAD_ARQC_CHECKLIST_FAILURE action.
 * @param {number} id The id of the checklist.
 * @returns {function} A function that returns a Promise.
 */
export function fetchArqcChecklistById(id) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/ARQC/${id}`)
      .then(response => {
        return response.json();
      })
      .then(arqcChecklist => {
        if (actionHelpers.isErrorResponse(arqcChecklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_ARQC_CHECKLIST_FAILURE,
            arqcChecklist,
          );
        }

        if (arqcChecklist !== undefined && arqcChecklist !== null) {
          setActiveChecklist(arqcChecklist)(dispatch);
          
        }

        fetchPreviousArqcChecklist(
          arqcChecklist.reportPeriodID,
          arqcChecklist.reitTypeID,
        )(dispatch);
        dispatch({ type: actions.TASK_END, [pendingTask]: end });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_ARQC_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch the PSQ checklist record with the specified ID. If unsuccessful this will dispatch the LOAD_PSQ_CHECKLIST_FAILURE action.
 * @param {number} id The id of the checklist.
 * @returns {function} A function that returns a Promise.
 */
export function fetchPsqChecklistById(id, includeDiscrepancyMessages) {
    return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/PSQ/${id}${includeDiscrepancyMessages ? '?includeDiscrepancyMessages=true' : ''}`)
      .then(response => {
        return response.json();
      })
      .then(psqChecklist => {
        if (actionHelpers.isErrorResponse(psqChecklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_PSQ_CHECKLIST_FAILURE,
            psqChecklist,
          );
        }

        if (psqChecklist !== undefined && psqChecklist !== null) {
          setActiveChecklist(psqChecklist)(dispatch);
        }

        fetchPreviousPsqChecklist(
          psqChecklist.reportPeriodID,
          psqChecklist.propertyID,
          psqChecklist.propertyType,
        )(dispatch);
        dispatch({
          type: actions.LOAD_PSQ_CHECKLIST_SUCCESS,
          psqChecklist,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_PSQ_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch ARQC checklist by ID. If successful this will dispatch the LOAD_EXTERNAL_ARQC_SUCCESS
 * action, otherwise it will dispatch the LOAD_EXTERNAL_ARQC_FAILURE action.
 * @param {number} checklistId The id of the ARQC checklist.
 * @param {boolean} excludeUpdatingCurrentChecklist If true, the current checklist will not be updated or dispatched LOAD_EXTERNAL_ARQC_SUCCESS.
 * @returns {function} A function that returns a Promise.
 */
export function fetchExternalArqcChecklistsById(checklistId, excludeUpdatingCurrentChecklist = false) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const authHeader = actionHelpers.buildAuthHeader(checklistId, 'ARQC');
    return fetch(`/checklists/external/ARQC/${checklistId}`, {
      headers: authHeader && new Headers({ Authorization: authHeader }),
    })
      .then(response => {
        return response.json(); // This will either be the checklist or UnsuccessfulResponse
      })
      .then(responseBody => {
        if (actionHelpers.isErrorResponse(responseBody)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_EXTERNAL_ARQC_FAILURE,
            responseBody,           
          );
        }

        const arqcChecklists = [responseBody];
        dispatch({
          type: !excludeUpdatingCurrentChecklist
                ? actions.LOAD_EXTERNAL_ARQC_SUCCESS
                : actions.EXTERNAL_ARQC_EXCLUDE_UPDATING_CURRENT_CHECKLIST_SUCCESS,
          arqcChecklists,
          [pendingTask]: end,
        });
        if (arqcChecklists.length > 0 && !excludeUpdatingCurrentChecklist) {
          setActiveChecklist(arqcChecklists[0])(dispatch);
          
          fetchPreviousArqcChecklist(
            arqcChecklists[0].reportPeriodID,
            arqcChecklists[0].reitTypeID,
            authHeader,
          )(dispatch);
        }
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_EXTERNAL_ARQC_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch the ARQC checklist record with the specified ID. If unsuccessful this will dispatch the LOAD_EXTERNAL_ARQC_FAILURE action.
 * @param {number} id The id of the checklist.
 * @param {boolean} excludeUpdatingCurrentChecklist If true, the current checklist will not be updated or dispatched LOAD_EXTERNAL_ARQC_SUCCESS.
 * @returns {function} A function that returns a Promise.
 */
export function fetchExternalPermArqcChecklists(id, excludeUpdatingCurrentChecklist = false) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`checklists/external/perm/ARQC/${id}`)
      .then(response => {
        return response.json();
      })
      .then(arqcChecklist => {
        if (actionHelpers.isErrorResponse(arqcChecklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_EXTERNAL_ARQC_FAILURE,
            arqcChecklist,
          );
        }

        const arqcChecklists = [arqcChecklist];
        dispatch({
          type:  !excludeUpdatingCurrentChecklist
                  ? actions.LOAD_EXTERNAL_ARQC_SUCCESS
                  : actions.EXTERNAL_ARQC_EXCLUDE_UPDATING_CURRENT_CHECKLIST_SUCCESS,
          arqcChecklists,
          [pendingTask]: end,
        });
        if (arqcChecklists.length > 0 && !excludeUpdatingCurrentChecklist) {
          setActiveChecklist(arqcChecklists[0])(dispatch);
         
          fetchPreviousArqcChecklist(
            arqcChecklists[0].reportPeriodID,
            arqcChecklists[0].reitTypeID,
          )(dispatch);
        }
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_EXTERNAL_ARQC_FAILURE,
          null,
          error,
        );
      });
  };
}


/**
 * Fetch PSQ checklist by ID. If successful this will dispatch the LOAD_EXTERNAL_PSQ_SUCCESS
 * action, otherwise it will dispatch the LOAD_EXTERNAL_PSQ_FAILURE action.
 * @param {number} checklistId The id of the PSQ checklist.
 * @param {boolean} excludeUpdatingCurrentChecklist If true, the current checklist will not be updated or dispatched LOAD_EXTERNAL_PSQ_SUCCESS .
 * @returns {function} A function that returns a Promise.
 */
export function fetchExternalPsqChecklistsById(checklistId, excludeUpdatingCurrentChecklist = false) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const authHeader = actionHelpers.buildAuthHeader(checklistId, 'PSQ');
    return fetch(`/checklists/external/PSQ/${checklistId}`, {
      headers: authHeader && new Headers({ Authorization: authHeader }),
    })
      .then(response => {
        return response.json(); // This will either be the checklist or UnsuccessfulResponse
      })
      .then(responseBody => {       

        if (actionHelpers.isErrorResponse(responseBody)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_EXTERNAL_PSQ_FAILURE,
            responseBody,
          );
        }

        const psqChecklists = [responseBody];
        dispatch({
          type: !excludeUpdatingCurrentChecklist
                  ? actions.LOAD_EXTERNAL_PSQ_SUCCESS
                  : actions.EXTERNAL_PSQ_EXCLUDE_UPDATING_CURRENT_CHECKLIST_SUCCESS,
          psqChecklists,
          [pendingTask]: end,
        });
        if (psqChecklists.length > 0 && !excludeUpdatingCurrentChecklist) {
          setActiveChecklist(psqChecklists[0])(dispatch);
          
          fetchPreviousPsqChecklist(
            psqChecklists[0].reportPeriodID,
            psqChecklists[0].propertyID,
            psqChecklists[0].propertyType,
            authHeader,
          )(dispatch);
        }
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_EXTERNAL_PSQ_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch the PSQ checklist records belonging to the reit period previous to the one specified. If successful this will dispatch the LOAD_PREVIOUS_PSQ_CHECKLIST_SUCCESS
 * action, otherwise it will dispatch the LOAD_PREVIOUS_PSQ_CHECKLIST_FAILURE action.
 * @param {number} currentPeriodId The id of the current period.
 * @param {number} propertyId The ID of the property.
 * @param {number} propertyType The type of the property.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function fetchPreviousPsqChecklist(
  currentPeriodId,
  propertyId,
  propertyType,
  authHeader,
) {
    return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(
      `/checklists/PSQ/previous?currentPeriodId=${currentPeriodId}&propertyId=${propertyId}&propertyType=${propertyType}`,
      {
        headers:
          (authHeader && new Headers({ Authorization: authHeader })) || null,
      },
    )
      .then(response => {
        return response.json().catch(() => {
          return null;
        });
      })
      .then(psqChecklist => {
        if (actionHelpers.isErrorResponse(psqChecklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_PREVIOUS_PSQ_CHECKLIST_FAILURE,
            psqChecklist,
          );
        }

        dispatch({
          type: actions.LOAD_PREVIOUS_PSQ_CHECKLIST_SUCCESS,
          psqChecklist,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_PREVIOUS_PSQ_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch the ARQC checklist records belonging to the reit period previous to the one specified. If successful this will dispatch the LOAD_PREVIOUS_ARQC_CHECKLIST_SUCCESS
 * action, otherwise it will dispatch the LOAD_PREVIOUS_ARQC_CHECKLIST_FAILURE action.
 * @param {number} currentPeriodId The id of the current period.
 * @param {number} reitType The type of the REIT.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function fetchPreviousArqcChecklist(
  currentPeriodId,
  reitType,
  authHeader,
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(
      `/checklists/ARQC/previous?currentPeriodId=${currentPeriodId}&reitType=${reitType}`,
      {
        headers:
          (authHeader && new Headers({ Authorization: authHeader })) || null,
      },
    )
      .then(response => {
        return response.json().catch(() => {
          return null;
        });
      })
      .then(arqcChecklist => {
        if (actionHelpers.isErrorResponse(arqcChecklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_PREVIOUS_ARQC_CHECKLIST_FAILURE,
            arqcChecklist,
          );
        }

        dispatch({
          type: actions.LOAD_PREVIOUS_ARQC_CHECKLIST_SUCCESS,
          arqcChecklist,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_PREVIOUS_ARQC_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Saves a checklist to the REIT API database.
 * @param {any} checklist The checklist
 * @param {any} shouldRefresh True if the checklist should refresh
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @param {bool} [isExternal=false] True if saving by an external user, otherwise false.
 * @returns {Promise} A Promise
 */
export function saveChecklist(
  checklist,
  shouldRefresh,
  authHeader,
  isExternal = false,
) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const payload = checklist;
    const isShouldRefresh = shouldRefresh;
    return fetch(`/checklists/${checklist.checklistTypeID}/${checklist.checklistID}`, {
      headers: {
        Accept:
          'application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'Content-Type': 'application/json',
        Authorization: authHeader || '',
      },
      method: 'PUT',
      body: JSON.stringify(payload),
    })
      .then(response => {
        return response.json();
      })
      .then(checklist => {
        if (actionHelpers.isErrorResponse(checklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.UPDATE_CHECKLIST_FAILURE,
            checklist,
          );
        }

        if (isShouldRefresh && checklist.checklistTypeID === 'ARQC') {
          fetchArqcChecklistsByPeriod(
            checklist.reportPeriodID,
            authHeader,
            checklist.checklistID,
          )(dispatch);
        } else if (isShouldRefresh) {
          fetchPsqChecklistsByPeriod(
            checklist.reportPeriodID,
            authHeader,
            checklist.checklistID,
          )(dispatch);
        }

        if (!isExternal && checklist.checklistTypeID === 'ARQC') {
          fetchPreviousArqcChecklist(
            checklist.reportPeriodID,
            checklist.reitTypeID,
            authHeader,
          )(dispatch);
        } else if (!isExternal) {
          fetchPreviousPsqChecklist(
            checklist.reportPeriodID,
            checklist.propertyID,
            checklist.propertyType,
            authHeader,
          )(dispatch);
        }

        dispatch({
          type: actions.UPDATE_CHECKLIST_SUCCESS,
          checklist,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.UPDATE_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Creates a checklist to the REIT API database.
 * @param {any} createRequest The create request
 * @returns {Promise} A Promise
 */
export function createChecklist(createRequest) {
  const payload = createRequest;
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
  return fetch(`/checklists`, {
    headers: {
      Accept:
        'application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(response => {
    return response.json();
  })
  .then(checklist => {
    if (actionHelpers.isErrorResponse(checklist)) {
      return actionHelpers.dispatchErrorAndEndTask(
        dispatch,
        actions.CREATE_CHECKLIST_FAILURE,
        checklist,
      );
    }

    return dispatch({
      type: actions.CREATE_CHECKLIST_SUCCESS,
      checklist,
      [pendingTask]: end,
    });
  })
  .catch(error => {
    return actionHelpers.dispatchErrorAndEndTask(
      dispatch,
      actions.CREATE_CHECKLIST_FAILURE,
      null,
      error,
    );
  });
};
}

/**
 * Delete a checklist
 * @param {any} type The checklist type to delete (PSQ or ARQC)
 * @param {any} id The ID of the checklist to delete.
 * @returns {any} A function the returns a Promise.
 */
export function deleteChecklist(type, id) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/${type}/${id}`, {
      method: 'DELETE',
    })
      .then(response => {
        return response.json();
      })
      .then(checklist => {
        if (actionHelpers.isErrorResponse(checklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_CHECKLIST_FAILURE,
            checklist,
          );
        }

        return dispatch({
          type: actions.DELETE_CHECKLIST_SUCCESS,
          checklist,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.DELETE_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Remove deleted checklist from state.
 * @returns {any} A function that dispatches an action.
 */
export function clearDeletedChecklist() {
  return function (dispatch) {
    return dispatch({ type: actions.DELETE_CHECKLIST_CLEAR });
  };
}

/**
 * Sets the active checklist.
 * @param {any} checklist The checklist to become active
 * @param {any} previousChecklist The previous period checklist
 * @returns {Promise} A Promise
 */
export function setActiveChecklist(checklist, previousChecklist) {
  return function (dispatch) {
    let modifiedChecklist = {};

    modifiedChecklist = mapPreviousAnswers(checklist, previousChecklist);

    if (checklist.checklistTypeID === 'ARQC') {
      dispatch({
        type: actions.SET_ARQC_ACTIVE_CHECKLIST_SUCCESS,
        activeChecklist: modifiedChecklist,
      });
    } else {
      dispatch({
        type: actions.SET_PSQ_ACTIVE_CHECKLIST_SUCCESS,
        activeChecklist: modifiedChecklist,
      });
    }
  };
}

/**
 * Maps the answers from the previous checklist to the current checklist
 * @param {any} checklist The checklist to map answers to.
 * @param {any} previousChecklist The previous checklist to map answers from.
 * @returns {Promise} A Promise
 */
export function mapPreviousAnswers(checklist, previousChecklist) {
  let ck = JSON.parse(JSON.stringify(checklist));

  ck.mappedPreviousAnswers = true;

  if (
    previousChecklist === undefined ||
    previousChecklist === null ||
    previousChecklist.content == undefined ||
    previousChecklist.content == null
  ) {
    return clearPreviousAnswers(ck);
  }

  if (
    !checklist ||
    !Array.isArray(checklist.content) ||
    !previousChecklist ||
    !Array.isArray(previousChecklist.content)
  ) {
    return checklist;
  }

  // Check for content
  if (!ck.content) return;

  let allPreviousQuestions = [];

  if (previousChecklist.content) {
    // Get all questions from previous checklist from all sections and combine them into single array (i.e. allPreviousQuestions)
    previousChecklist.content.forEach((section) => {
      allPreviousQuestions = allPreviousQuestions.concat(section.items);
    });

    if (allPreviousQuestions.length > 0) {
      // Compare each section with previous checklist
      for (let i = 0; i < ck.content.length; i++) {
        let section = ck.content[i];

        // Continue if no section questions
        if (!section.items) continue;

        // Compare each parent question from section with previous checklist
        for (let j = 0; j < section.items.length; j++) {
          let item = section.items[j];

          // Get the question based on "id" from previous checklist
          let previousItem = allPreviousQuestions.find(x => x.id === item.id);

          // Continue if no previous question
          if (!previousItem) {
            continue;
          }

          if (
            previousItem.selectedAnswers &&
            previousItem.selectedAnswers.length > 0
          ) {
            item.previousAnswers = previousItem.selectedAnswers;
          } else if (
            previousItem.defaultAnswers &&
            previousItem.defaultAnswers.length > 0
          ) {
            item.previousAnswers = previousItem.defaultAnswers;
          } else {
            item.previousAnswers = previousItem.selectedAnswers;
          }

          // Continue if no children
          if (!item.children) continue;

          // Compare each child question from parent with previous checklist
          for (let k = 0; k < item.children.length; k++) {
            let child = item.children[k];

            // Continue if no previous children
            if (!previousItem.children) continue;

            let previousChild = previousItem.children.find(x => x.id === child.id);

            // Continue if no previous child question
            if (!previousChild) {
              continue;
            }

            if (
              previousChild.selectedAnswers &&
              previousChild.selectedAnswers.length > 0
            ) {
              child.previousAnswers = previousChild.selectedAnswers;
            } else if (
              previousChild.defaultAnswers &&
              previousChild.defaultAnswers.length > 0
            ) {
              child.previousAnswers = previousChild.defaultAnswers;
            } else {
              child.previousAnswers = previousChild.selectedAnswers;
            }
          }
        }
      }
    }
  }

  return ck;
}

/**
 * Clear the previous answers on a checklist.
 * @param {any} checklist The checklist.
 * @returns {any} The checklist.
 */
export function clearPreviousAnswers(checklist) {
  // Check for content
  if (!checklist.content) return;

  for (let i = 0; i < checklist.content.length; i++) {
    let section = checklist.content[i];

    if (!section.items) continue;

    for (let j = 0; j < section.items.length; j++) {
      let item = section.items[j];

      item.previousAnswers = [];

      // Continue if no children
      if (!item.children) continue;

      for (let k = 0; k < item.children.length; k++) {
        let child = item.children[k];

        child.previousAnswers = [];
      }
    }
  }

  return checklist;
}

/**
 * Reverts a checklist state to the previous state.
 * @param {any} checklist The checklist
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {any} A function that returns a Promise
 */
export function revertChecklistState(checklist, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    dispatch({
      type: actions.LOAD_PREVIOUS_PSQ_CHECKLIST_SUCCESS,
      psqChecklist: {},
    });
    const payload = checklist;

    return fetch(`/checklists/${checklist.checklistTypeID}/${checklist.checklistID}/revertstate`, {
      headers: {
        Accept:
          'application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'Content-Type': 'application/json',
        Authorization: authHeader || '',
      },
      method: 'PUT',
      body: JSON.stringify(payload),
    })
      .then(response => {
        return response.json();
      })
      .then(checklist => {
        if (actionHelpers.isErrorResponse(checklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.UPDATE_CHECKLIST_FAILURE,
            checklist,
          );
        }

        if (checklist.checklistTypeID === 'ARQC') {
          fetchArqcChecklistsByPeriod(
            checklist.reportPeriodID,
            authHeader,
            checklist.checklistID,
          )(dispatch);

          fetchPreviousArqcChecklist(
            checklist.reportPeriodID,
            checklist.reitTypeID,
            authHeader,
          )(dispatch);
        } else {
          fetchPsqChecklistsByPeriod(
            checklist.reportPeriodID,
            authHeader,
            checklist.checklistID,
            )(dispatch);

          fetchPreviousPsqChecklist(
            checklist.reportPeriodID,
            checklist.propertyID,
            checklist.propertyType,
            authHeader,
          )(dispatch);
        }

        dispatch({
          type: actions.UPDATE_CHECKLIST_SUCCESS,
          checklist,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.UPDATE_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Reverts a checklist state to the previous state (used for permanent external users).
 * @param {any} checklist The checklist
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {any} A function that returns a Promise
 */
export function revertChecklistStateExternal(checklist, reitId, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const payload = checklist;

    return fetch(`/checklists/${checklist.checklistTypeID}/${checklist.checklistID}/revertstate`, {
      headers: {
        Accept:
          'application/json',
        'Content-Type': 'application/json',
        Authorization: authHeader || '',
      },
      method: 'PUT',
      body: JSON.stringify(payload),
    })
      .then(response => {
        return response.json();
      })
      .then(checklist => {
        if (actionHelpers.isErrorResponse(checklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.UPDATE_CHECKLIST_FAILURE,
            checklist,
          );
        }

        dispatch(fetchChecklistListForExternalUser(reitId));

        dispatch({
          type: actions.UPDATE_CHECKLIST_SUCCESS,
          checklist,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.UPDATE_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Get email addresses for external PSQ.
 * @param {any} checklistId The ID of the checklist.
 * @returns {any} A Promise.
 */
export function getExternalPsqEmails(checklistId) {
  const authHeader = actionHelpers.buildAuthHeader(checklistId, 'PSQ');
  return fetch(`checklists/external/psq/${checklistId}/emails`, {
    headers: authHeader && new Headers({ Authorization: authHeader }),
  });
}

/**
 * Get a summary of client, REIT, and properties for a given period.
 * @param {any} periodId The ID of the report period.
 * @returns {any} A Promise.
 */
export function getReportPeriodSummary(periodId) {
  return fetch(`periods/${periodId}/summary`);
}

/**
 * Send the PSQ internal review email.
 * @param {any} checklistId The external checklist ID.
 * @param {any} clientEmails The email addresses to send the review to.
 * @returns {any} A Promise.
 */
export function sendPsqInternalReviewEmail(checklistId, clientEmails) {
  //let data = { clientEmails: clientEmails };
  let body = JSON.stringify(clientEmails);

  const authHeader = actionHelpers.buildAuthHeader(checklistId, 'PSQ');
  return fetch(
    `checklists/external/psq/${checklistId}/sendInternalReviewEmail`,
    {
      
      headers:  (authHeader && new Headers({Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authHeader })) || null,
      method: 'POST',
      body: body,
    },
  );
}

export function sendPsqInternalReviewEmailForExtPerm(checklistId, clientEmails) {
  //let data = { clientEmails: clientEmails };
  let body = JSON.stringify(clientEmails);
  return fetch(
    `checklists/externalPerm/psq/${checklistId}/sendInternalReviewEmail`,
    {
      
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
      method: 'POST',
      body: body,
    },
  );
}
/**
 * Save new comment on a checklist question
 * @param {string} type The checklist type (PSQ or ARQC)
 * @param {number} id The ID of the checklist.
 * @param {number} questionId The ID of the question within the checklist
 * @param {string} commentText The comment text.
 * @param {array} unsavedQuestions The array of unsaved questions.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {any} A function that returns a Promise.
 */
export function saveNewChecklistComment(type, id, questionId, commentText, unsavedQuestions, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    if (authHeader) {
      headers['Authorization'] = authHeader;
    }

    return fetch(`/checklists/${type}/${id}/${questionId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        commentText,
        unsavedQuestions
      })
    })
      .then(response => {
        return response.json();
      })
      .then(comments => {
        if (actionHelpers.isErrorResponse(comments)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.SAVE_NEW_CHECKLIST_COMMENT_FAILURE
          );
        }

        dispatch({
          type: actions.SAVE_NEW_CHECKLIST_COMMENT_SUCCESS,
          [pendingTask]: end
        });

        return comments;
      })
      .catch(error => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.SAVE_NEW_CHECKLIST_COMMENT_FAILURE,
          null,
          error,
        );
      });
  };
}


/**
* Edit saved comment on a checklist question
* @param { string } type The checklist type(PSQ or ARQC)
* @param { number } id The ID of the checklist.
* @param { number } questionId The ID of the question within the checklist
* @param { string } commentText The comment text.
* @param { string } commentId (GUID) for comment text.
* @param { object } authHeader The Authorization header.  If not supplied it will be generated.
* @returns { any } A function that returns a Promise.
*/
export function editSavedChecklistComment(type, id, questionId, commentText, commentId, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    if (authHeader) {
      headers['Authorization'] = authHeader;
    }

    return fetch(`/checklists/${type}/${id}/${questionId}/${commentId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(commentText)
    })
      .then(response => {
        return response.json();
      })
      .then(comments => {
        if (actionHelpers.isErrorResponse(comments)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.EDIT_SAVED_CHECKLIST_COMMENT_FAILURE
          );
        }

        dispatch({
          type: actions.EDIT_SAVED_CHECKLIST_COMMENT_SUCCESS,
          [pendingTask]: end
        });

        return comments;
      })
      .catch(error => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.EDIT_SAVED_CHECKLIST_COMMENT_FAILURE,
          null,
          error,
        );
      });
  };
}


/**
* Delete saved comment on a checklist question
* @param { string } type The checklist type(PSQ or ARQC)
* @param { number } id The ID of the checklist.
* @param { number } questionId The ID of the question within the checklist
* @param { string } commentId (GUID) for comment text.
* @param { object } authHeader The Authorization header.  If not supplied it will be generated.
* @returns { any } A function that returns a Promise.
*/
export function deleteSavedChecklistComment(type, id, questionId, commentId, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    if (authHeader) {
      headers['Authorization'] = authHeader;
    }

    return fetch(`/checklists/${type}/${id}/${questionId}/${commentId}`, {
      method: 'DELETE',
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(comments => {
        if (actionHelpers.isErrorResponse(comments)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_SAVED_CHECKLIST_COMMENT_FAILURE
          );
        }

        dispatch({
          type: actions.DELETE_SAVED_CHECKLIST_COMMENT_SUCCESS,
          [pendingTask]: end
        });

        return comments;
      })
      .catch(error => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.DELETE_SAVED_CHECKLIST_COMMENT_FAILURE,
          null,
          error,
        );
      });
  };
}


/**
* Delete attachment on a checklist question
* @param { number } checklistFileAttachmentId The ID of the attachment to delete
* @param { string } checklistType The type of checklist.
* @param { number } checklistId The id of the checklist.
* @param { number } periodId The id of the period.
* @param { object } authHeader The Authorization header.  If not supplied it will be generated.
* @returns { any } A function that returns a Promise.
*/
export function deleteChecklistAttachment(checklistFileAttachmentId, checklistType, checklistId, periodId, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    const headers = {
      'Accept': 'application/json'
    };

    if (authHeader) {
      headers['Authorization'] = authHeader;
    }

    return fetch(`/checklists/checklistFiles/${checklistFileAttachmentId}`, {
      method: 'DELETE',
      headers,
    })
      .then(response => {
        if (response.ok) {
          return null;
        }

        return response.json();
      })
      .then(response => {
        if (actionHelpers.isErrorResponse(response)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_CHECKLIST_ATTACHMENT_FAILURE
          );
        }

        dispatch({
          type: actions.DELETE_CHECKLIST_ATTACHMENT_SUCCESS,
          [pendingTask]: end
        });

        // if periodId is passed, the caller is FileManagementPage
        // otherwise the caller is the checklist page so fetch the checklist
        if (!periodId) {
          fetchChecklistFiles(checklistId, checklistType, authHeader)(dispatch);
        }

        return response;
      })
      .catch(error => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.DELETE_CHECKLIST_ATTACHMENT_FAILURE,
          null,
          error,
        );
      });
  };
}


/**
 * Retrieves a list of the checklists a permanent external user has access to for the given client.
 * @param {any} clientId The ID of the client.
 * @returns {any} A Promise.
 */
export function fetchChecklistListForExternalUser(reitId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/checklistsExternal/${reitId}`).then(response => {
      return response.json();
    }).then(checklistsData => {
      if (actionHelpers.isErrorResponse(checklistsData)) {
        return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_EXTERNAL_CHECKLIST_LIST_FAILURE, checklistsData);
      }
      dispatch({ type: actions.LOAD_EXTERNAL_CHECKLIST_LIST_SUCCESS, checklistList: checklistsData, [pendingTask]: end });
    });
  };
}

/**
 * Fetch all Custom Question records belonging to the client. If successful this will dispatch the LOAD_CUSTOM_QUESTIONS_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_QUESTIONS_FAILURE action.
 * @param {number} clientId The id of the client that owns the Custom Questions.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCustomQuestionList(clientId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/customQuestions/${clientId}`)
      .then(response => {
        return response.json();
      })
      .then(customQuestionList => {
        if (actionHelpers.isErrorResponse(customQuestionList)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CUSTOM_QUESTIONS_FAILURE,
            customQuestionList,
          );
        }

        dispatch({
          type: actions.LOAD_CUSTOM_QUESTIONS_SUCCESS,
          customQuestionList,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_CUSTOM_QUESTIONS_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Creates a custom question record to the REIT API database.
 * @param {any} createRequest The create request
 * @returns {Promise} A Promise
 */
export function createCustomQuestion(createRequest) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const payload = createRequest;

    return fetch(`/checklists/customQuestions`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (response.ok) {
          return null;
        }
        else {
          return response.json();
        }
      })
      .then(response => {
        if (actionHelpers.isErrorResponse(response)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.CREATE_CUSTOM_QUESTION_FAILURE,
            response,
          );
        }

        dispatch(fetchCustomQuestionList(createRequest.clientID));

        dispatch({
          type: actions.CREATE_CUSTOM_QUESTION_SUCCESS,
          response,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CREATE_CUSTOM_QUESTION_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
* Delete saved comment on a checklist question
* @param { string } type The checklist type(PSQ or ARQC)
* @param { number } id The ID of the checklist.
* @param { number } questionId The ID of the question within the checklist
* @param { string } commentId (GUID) for comment text.
* @param { object } authHeader The Authorization header.  If not supplied it will be generated.
* @returns { any } A function that returns a Promise.
*/
export function deleteCustomQuestion(customQuestionID, customQuestionList) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/customQuestions/${customQuestionID}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (response.ok) {
          return null;
        }
        else {
          return response.json();
        }
      })
      .then(response => {
        if (actionHelpers.isErrorResponse(response)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_CUSTOM_QUESTION_FAILURE,
            response,
          );
        }

        // remove the deleted custom question
        for (let i = 0; i < customQuestionList.length; i++) {
          if (customQuestionList[i].customQuestionID === customQuestionID) {
            customQuestionList.splice(i, 1);
          }
        }

        dispatch({
          type: actions.DELETE_CUSTOM_QUESTION_SUCCESS,
          customQuestionList,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.DELETE_CUSTOM_QUESTION_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Creates a custom question record to the REIT API database.
 * @param {any} createRequest The create request
 * @returns {Promise} A Promise
 */
export function updateCustomQuestion(updateRequest) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const payload = updateRequest;

    return fetch(`/checklists/customQuestions`, {
      headers: {
        Accept:
          'application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (response.ok) {
          return null;
        }
        else {
          return response.json();
        }
      })
      .then(response => {
        if (actionHelpers.isErrorResponse(response)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.UPDATE_CUSTOM_QUESTION_FAILURE,
            response,
          );
        }

        dispatch(fetchCustomQuestionList(updateRequest.clientID));

        dispatch({
          type: actions.UPDATE_CUSTOM_QUESTION_SUCCESS,
          response,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.UPDATE_CUSTOM_QUESTION_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Fetch all the calendar years having checklists. If successful this will dispatch the LOAD_CHECKLIST_CALENDAR_YEARS_SUCCESS
 * action, otherwise it will dispatch the LOAD_CHECKLIST_CALENDAR_YEARS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function fetchChecklistCalendarYears(
    clientId
) {
    return function (dispatch) {
        if (!clientId) {
            return dispatch({
                type: actions.LOAD_CHECKLIST_CALENDAR_YEARS_SUCCESS,
                checklistCalendarYears: []
            });
        }

        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/checklists/checklistCalendarYears/${clientId}`)
            .then(response => {
                return response.json();
            })
            .then(checklistCalendarYears => {
                if (actionHelpers.isErrorResponse(checklistCalendarYears)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.LOAD_CHECKLIST_CALENDAR_YEARS_FAILURE,
                        checklistCalendarYears,
                    );
                }
                dispatch({
                    type: actions.LOAD_CHECKLIST_CALENDAR_YEARS_SUCCESS,
                    checklistCalendarYears,
                    [pendingTask]: end,
                });
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.LOAD_CHECKLIST_CALENDAR_YEARS_FAILURE,
                    null,
                    error,
                );
            });
    };
}

/**
 * Fetch all the calendar years for a given client and checklist type. If successful this will dispatch the LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_SUCCESS
 * action, otherwise it will dispatch the LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {string} checklistType The checklist type.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function fetchChecklistCalendarYearsByClientAndType(clientId, checklistType) {
  return (dispatch) => {
    if (!clientId || !checklistType) {
        return dispatch({
          type: actions.LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_SUCCESS,
          checklistCalendarYears: []
        });
      }

      dispatch({type: actions.TASK_BEGIN, [pendingTask]: begin});

      return fetch(`/checklists/checklistCalendarYears/${clientId}/${checklistType}`)
      .then(response => {
        return response.json();
      })
      .then(checklistCalendarYears => {
        if (actionHelpers.isErrorResponse(checklistCalendarYears)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_FAILURE,
            checklistCalendarYears
            );
        }

        dispatch({
          type: actions.LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_SUCCESS,
          checklistCalendarYears,
          [pendingTask]: end,
      });
      return checklistCalendarYears;
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_CHECKLIST_CALENDAR_YEARS_BY_CLIENT_AND_TYPE_FAILURE,
            null,
            error,
        );
    });
  };
}

/**
 * Fetch all the checklists belonging to the specified client and (calendar year/report period id/checklist type). 
 * If successful this will dispatch the LOAD_MANAGE_CHECKLIST_SUCCESS action, 
 * otherwise it will dispatch the LOAD_MANAGE_CHECKLIST_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} calendarYear The calendar year.
 * @param {number} reportPeriodId The report period id.
 * @param {string} checklistType The checklist type.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
 export function fetchManageChecklists(
  clientId,
  calendarYear,
  reportPeriodId,
  checklistType
 ) {
  // Manage Checklist - When Checklist type is null then Calendar year should have value
  // PSQ/ARQC - When Checklist type is not null then Report Period Id should have value   
  return function (dispatch) {      
      if (!clientId || (!calendarYear && !checklistType) || (!reportPeriodId && checklistType)) {
          return dispatch({
              type: actions.LOAD_MANAGE_CHECKLIST_SUCCESS,
              manageChecklists: []
          });
      }
      
      let url = `/checklists/manage?clientId=${clientId}`;
      if (calendarYear) {
          url += `&calendarYear=${calendarYear}`;
      }
      if (reportPeriodId) {
          url += `&reportPeriodId=${reportPeriodId}`;
      }
      if (checklistType) {
          url += `&checklistType=${checklistType}`;
      }

      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

      return fetch(url)
      .then(response => {
        return response.json();
      })
      .then(manageChecklists => {
        if (actionHelpers.isErrorResponse(manageChecklists)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_MANAGE_CHECKLIST_FAILURE,
            manageChecklists,
          );
          }
          
        // Transform the manage checklists into flat checklist structure
        dispatch({
          type: actions.LOAD_MANAGE_CHECKLIST_SUCCESS,
          manageChecklists: flattenManageChecklists(manageChecklists),
          [pendingTask]: end,
        });
        return manageChecklists;
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_MANAGE_CHECKLIST_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Transform the manage checklists into flat checklist structure for each checklist email details
 * @param {*} manageChecklists 
 * @returns flatManageChecklists
 */
function flattenManageChecklists(manageChecklists){
  let flatManageChecklists=[];
  
  manageChecklists.forEach(mc => {
    // Get checklist email details for each checklist
    const checklistEmailDetails = mc.checklistEmailDetails;

    // Remove checklist email details property from checklist
    delete mc.checklistEmailDetails;

    // If checklist email detials not present then add the checklist object
    // Add recipients property as <checklistID>_<checklistType>
      if (!checklistEmailDetails || checklistEmailDetails.length <= 0) {
      flatManageChecklists.push({...mc, recipients:mc.checklistID+'_'+mc.checklistType, checklistSelection:false,
      recipientEmail:'', senderEmail:'', sentDate:'', expirationDate:''
    });
    }
    else {
      // For each checklist email detail return flattened object along with checklist
      // Add recipients property as <checklistID>_<checklistType>
      checklistEmailDetails.forEach(ced => {
        flatManageChecklists.push({...mc, recipients:mc.checklistID+'_'+mc.checklistType, checklistSelection:false, ...ced});
      });
    }
  });
  return flatManageChecklists;
}

/**
 * Fetch all the external checklists belonging to the specified client and calendar year. 
 * If successful this will dispatch the LOAD_EXTERNAL_CHECKLIST_SUCCESS action,
 * otherwise it will dispatch the LOAD_EXTERNAL_CHECKLIST_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} calendarYear The calendar year.
 * @param {string} checklistType The checklist type.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function fetchExternalChecklists(
    clientId,
    calendarYear,
    checklistType
) {
    return function (dispatch) {
        if (!clientId || !calendarYear) {
            return dispatch({
                type: actions.LOAD_EXTERNAL_CHECKLIST_SUCCESS,
                extChecklists: []
            });
        }

        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/checklists/external/${clientId}/${calendarYear}/${checklistType}`)
            .then(response => {
                return response.json();
            })
            .then(externalChecklists => {
                if (actionHelpers.isErrorResponse(externalChecklists)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.LOAD_EXTERNAL_CHECKLIST_FAILURE,
                        externalChecklists,
                    );
                }

                dispatch({
                    type: actions.LOAD_EXTERNAL_CHECKLIST_SUCCESS,
                    extChecklists: externalChecklists,
                    [pendingTask]: end,
                });
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.LOAD_EXTERNAL_CHECKLIST_FAILURE,
                    null,
                    error,
                );
            });
    };
}

/**
 * Add users(email addresses) to Checklists.
 * If successful this will dispatch the ADD_CHECKLIST_USERS_SUCCESS action,
 * otherwise it will dispatch the ADD_CHECKLIST_USERS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {array} requestData The array containing selected Checklist details and User(Email) details.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function addChecklistUsers(
    clientId,
    requestData
) {
    
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/checklists/manage/addChecklistUsers/${clientId}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(requestData)
        })
            .then(response => {
                return response.text();
            })
            .then(response => {
                const jsonResponse = response ? JSON.parse(response) : {};
                if (actionHelpers.isErrorResponse(jsonResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.ADD_CHECKLIST_USERS_FAILURE,
                        jsonResponse,
                    );
                }

                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return jsonResponse;
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.ADD_CHECKLIST_USERS_FAILURE,
                    null,
                    error,
                );
            });
    };
}

/**
 * Delete users(email addresses) from Checklists.
 * If successful this will dispatch the DELETE_CHECKLIST_USERS_SUCCESS action,
 * otherwise it will dispatch the DELETE_CHECKLIST_USERS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {array} requestData The array containing selected Checklist details and User(Email) details.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function deleteChecklistUsers(
    clientId,
    requestData
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/checklists/manage/deleteChecklistUsers/${clientId}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify(requestData)
        })
            .then(response => {
                return response.text();
            })
            .then(response => {
                const jsonResponse = response ? JSON.parse(response) : {};
                if (actionHelpers.isErrorResponse(jsonResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.DELETE_CHECKLIST_USERS_FAILURE,
                        jsonResponse,
                    );
                }

                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return jsonResponse;
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.DELETE_CHECKLIST_USERS_FAILURE,
                    null,
                    error,
                );
            });
    };
}

/**
 * Resend Checklists.
 * If successful this will dispatch the TASK_END action,
 * otherwise it will dispatch the RESEND_CHECKLISTS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {array} requestData The array containing selected Checklist details and User(Email) details.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
 export function resendChecklists(requestData) {
  return function (dispatch) {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

      return fetch(`/checklists/manage/queueResendChecklistEmails`, {
          headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(requestData)
      })
          .then(response => {
              return response.text();
          })
          .then(response => {
              const jsonResponse = response ? JSON.parse(response) : {};
              if (actionHelpers.isErrorResponse(jsonResponse)) {
                  return actionHelpers.dispatchErrorAndEndTask(
                      dispatch,
                      actions.RESEND_CHECKLISTS_FAILURE,
                      jsonResponse,
                  );
              }

              dispatch({ type: actions.TASK_END, [pendingTask]: end });
              return jsonResponse;
          })
          .catch(error => {
              actionHelpers.dispatchErrorAndEndTask(
                  dispatch,
                  actions.RESEND_CHECKLISTS_FAILURE,
                  null,
                  error,
              );
          });
  };
}

export async function updateChecklistsState(
    clientId,
    splitedChecklists,
    checklistStateID
) {
    return await processUpdatingChecklistsState(clientId, splitedChecklists, checklistStateID);
}

export async function processUpdatingChecklistsState(
    clientId,
    splitedChecklists,
    checklistStateID
) {
    for (let i = 0; i <= splitedChecklists.length - 1; i++) {        

        let requestData = {
            clientID: clientId,
            checklists: splitedChecklists[i],
            checklistStateID: checklistStateID
        };

        await setChecklistsState(clientId, requestData)
            .then(() => {
            }).catch((error) => {
                throw (error);
            });
    }
}

/**
 * Update Checklists state.
 * If successful this will dispatch the UPDATE_CHECKLISTS_STATE_SUCCESS action,
 * otherwise it will dispatch the UPDATE_CHECKLISTS_STATE_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {array} requestData The array containing selected Checklist details (Checklist Type, State) details.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function setChecklistsState(
    clientId,
    requestData
) {
    return fetch(`/checklists/manage/updateChecklistsState/${clientId}`, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(requestData)
    })
        .then(response => {
            return response.text();
        })
        .then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};
            if (actionHelpers.isErrorResponse(jsonResponse)) {
                throw jsonResponse;
            }
            return jsonResponse;
        })
        .catch(error => {
            throw error;
        });
}

export async function approveDenyClientRequest(
    clientId,
    splitedChecklists,
    reopenRequestStatusID
) {
    return await processApproveDenyClientReopenRequest(clientId, splitedChecklists, reopenRequestStatusID);  
}

export async function processApproveDenyClientReopenRequest(
    clientId,
    splitedChecklists,
    reopenRequestStatusID
) {
    for (let i = 0; i <= splitedChecklists.length - 1; i++) {
        let requestData = {
            clientID: clientId,
            checklists: splitedChecklists[i],
            reopenRequestStatusID: reopenRequestStatusID,
            requesterEmailAddress: splitedChecklists[i][0].requesterEmailAddress
        };

        await approveDenyClientReopenRequest(clientId, requestData)
            .then(() => {
            }).catch((error) => {
              throw (error);
            });
    }
}

export function approveDenyClientReopenRequest(clientId, requestData) {
    
    return fetch(
      `/checklists/manage/approveDenyClientReopenRequest/${clientId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(requestData),
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        const jsonResponse = response ? JSON.parse(response) : {};
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          throw jsonResponse;
        }
        return jsonResponse;
      })
      .catch((error) => {
        throw error;
      });
}


/**
 * Delete Checklists
 * If successful this will dispatch the DELETE_CHECKLISTS_SUCCESS action,
 * otherwise it will dispatch the DELETE_CHECKLISTS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {array} requestData The array containing selected Checklist details and User(Email) details.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
export function deleteChecklists(requestData) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/checklists/manage/deleteChecklists`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify(requestData)
        })
            .then(response => {
                return response.text();
            })
            .then(response => {
                const jsonResponse = response ? JSON.parse(response) : {};
                if (actionHelpers.isErrorResponse(jsonResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.DELETE_CHECKLISTS_FAILURE,
                        jsonResponse,
                    );
                }

                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return jsonResponse;
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.DELETE_CHECKLISTS_FAILURE,
                    null,
                    error,
                );
            });
    };
}

/**
 * Download Checklist Summary.
 * @returns {function} A Promise.
 */
export function exportChecklists(requestData) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

  return fetch(`/checklists/manage/exportChecklistSummary`, {
    headers: {
        'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(requestData)
}) .then(function (response) {
  if (!response.ok) {
      throw new Error('Unexpected error');
  }

  return response.blob().then(function (blob) {
      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition.split(';')[1].split('=')[1].trim();
      download(blob, filename);
      dispatch({ type: actions.TASK_END, [pendingTask]: end });
    
  });
}).catch(error => {
  return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.TASK_END, null, error);
});
};
}

/// Reset client id to hide refresh message on Manage Checklist section for that particular client
export function resetRefreshManageChecklistClient(clientId) {
  return function (dispatch) {
      dispatch({ type: actions.REFRESH_MANAGE_CHECKLIST_CLIENT, clientId : clientId === undefined ? 0 : clientId });  

  };
}

/**
 * Reopen Checklists Request.
 * If successful this will dispatch the TASK_END action,
 * otherwise it will dispatch the REOPEN_CHECKLIST_REQUESTS_FAILURE action.
 * @param {array} requestData The array containing selected Checklist details.
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {function} A function that returns a Promise.
 */
 export function requestToReopenChecklists(requestData) {
  return function (dispatch) {
      dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

      return fetch(`/checklists/external/perm/requestToReopenChecklists`, {
          headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(requestData)
      })
          .then(response => {
              return response.text();
          })
          .then(response => {
              const jsonResponse = response ? JSON.parse(response) : {};
              if (actionHelpers.isErrorResponse(jsonResponse)) {
                  return actionHelpers.dispatchErrorAndEndTask(
                      dispatch,
                      actions.REOPEN_CHECKLIST_REQUESTS_FAILURE,
                      jsonResponse,
                  );
              }

              dispatch({ type: actions.TASK_END, [pendingTask]: end });
              return jsonResponse;
          })
          .catch(error => {
              actionHelpers.dispatchErrorAndEndTask(
                  dispatch,
                  actions.REOPEN_CHECKLIST_REQUESTS_FAILURE,
                  null,
                  error,
              );
          });
  };
}

export async function splitAndSignoffChecklist(
    splitedChecklists,
    checklistType,
    signoffStatus
) {
    return await signoffChecklist(splitedChecklists, checklistType, signoffStatus);
}

// Send the partial request to API and throw error if any error in response.
export async function signoffChecklist(
    splitedChecklists,
    checklistType,
    isSignedOff
) {
    for (let i = 0; i <= splitedChecklists.length - 1; i++) {
        const checklistRequestDetails = splitedChecklists[i].map((c) => ({
            checklistID: c.checklistID,
            ChecklistType: checklistType,
            ReportPeriodID: c.reportPeriodID,
        }));

        const signoffChecklistRequest = {
            IsSignedOff: isSignedOff,
            SignoffChecklistRequestDetail: checklistRequestDetails,
        };

        await clientSignoffChecklist(signoffChecklistRequest).then(() => {

        }).catch((error) => {
            throw (error);
        });
    }
}

/**
 * Signoff Checklist.
 * If successful this will return response,
 * otherwise it will dispatch the SIGNOFF_CHECKLIST_REQUESTS_FAILURE action.
 * @param {array} requestData The array containing selected Checklist details.
 */
export function clientSignoffChecklist(requestData) {
    return fetch(`/checklists/SignoffChecklist`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(requestData),
    })
        .then(response => {
            return response.text();
        })
        .then((response) => {
            const jsonResponse = response ? JSON.parse(response) : {};
            if (actionHelpers.isErrorResponse(jsonResponse)) {
                throw (jsonResponse);
            }
            return jsonResponse;
        })
        .catch((error) => {
            throw (error);
        });
}

export async function splitAndsubmitForEYReview(
    splitedChecklists,
    checklistType
) {
    return await submitDataForReview(splitedChecklists, checklistType);
}

// Send the partial request to API and throw error if any error in response.
export async function submitDataForReview(
  splitedChecklists,
  checklistType
) {
  for (let i = 0; i <= splitedChecklists.length - 1; i++) {
    const checklistRequestDetails = splitedChecklists[i].map((c) => ({
      checklistID: c.checklistID,
      ChecklistType: checklistType,
      ReportPeriodID: c.reportPeriodID,
    }));
    const submitForEyReviewRequest = {
      ChecklistStateDescription: "Pending EY Review",
      SubmitForEyReviewRequestDetail: checklistRequestDetails,
    };

    await submitForEYReview(submitForEyReviewRequest).then(() => {
     
    }) .catch((error) => {
      throw (error);
    });
  }
}

/**
 * Submit for EY Review Request.
 * If successful this will return response,
 * otherwise it will dispatch the SUBMITFOREYREVIEW_REQUESTS_FAILURE action.
 * @param {array} requestData The array containing selected Checklist details.
 */
export function submitForEYReview(requestData) {
  return fetch(`/checklists/SubmitForEyReview`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: JSON.stringify(requestData),
  })
  .then(response => {
    return response.text();
})
    .then((response) => {
      const jsonResponse = response ? JSON.parse(response) : {};              
      if (actionHelpers.isErrorResponse(jsonResponse)) {
        throw (jsonResponse);
      }
      return jsonResponse;
    })
    .catch((error) => {
      throw (error);
    });
}

/**
 * Fetch the PSQ checklist record with the specified ID. If unsuccessful this will dispatch the LOAD_EXTERNAL_PSQ_FAILURE action.
 * @param {number} id The id of the checklist.
 * @param {boolean} excludeUpdatingCurrentChecklist If true, the current checklist will not be updated or dispatched LOAD_EXTERNAL_PSQ_SUCCESS.
 * @returns {function} A function that returns a Promise.
 */
 export function fetchExternalPermPsqChecklists(id, excludeUpdatingCurrentChecklist = false) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`checklists/external/perm/PSQ/${id}`)
      .then(response => {
        return response.json();
      })
      .then(psqChecklist => {
        if (actionHelpers.isErrorResponse(psqChecklist)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_EXTERNAL_PSQ_FAILURE,
            psqChecklist,
          );
        }

        const psqChecklists = [psqChecklist];
        dispatch({
          type: !excludeUpdatingCurrentChecklist
                ? actions.LOAD_EXTERNAL_PSQ_SUCCESS
                : actions.EXTERNAL_PSQ_EXCLUDE_UPDATING_CURRENT_CHECKLIST_SUCCESS,
          psqChecklists,
          [pendingTask]: end,
        });
        if (psqChecklists.length > 0 && !excludeUpdatingCurrentChecklist) {
          setActiveChecklist(psqChecklists[0])(dispatch);
         
          fetchPreviousPsqChecklist(
            psqChecklists[0].reportPeriodID,
            psqChecklists[0].propertyID,
            psqChecklists[0].propertyType,
          )(dispatch);
        }
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_EXTERNAL_PSQ_FAILURE,
          null,
          error,
        );
      });
  };
}

/**
 * Delete Custom PSQ Template
 * If successful this will dispatch the DELETE_CUSTOM_PSQ_TEMPLATE_SUCCESS action,
 * otherwise it will dispatch the DELETE_CUSTOM_PSQ_TEMPLATE_FAILURE action.
 * @param {number} clientId The id of the Client.
 * @param {number} checklistTemplateId The id of the Checklist template.
 * @returns {function} A function that returns a Promise.
 */
export function deleteCustomPSQTemplate(clientId, checklistTemplateId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/checklists/psqTemplates/${clientId}/${checklistTemplateId}`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            method: 'DELETE'
        })
            .then(response => {
                return response.text();
            })
            .then(response => {
                const jsonResponse = response ? JSON.parse(response) : {};
                if (actionHelpers.isErrorResponse(jsonResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.DELETE_CUSTOM_PSQ_TEMPLATE_FAILURE,
                        jsonResponse,
                    );
                }

                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return response;
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.DELETE_CUSTOM_PSQ_TEMPLATE_FAILURE,
                    null,
                    error,
                );
            });
    };
}


export function exportChecklistDeliverySchedule(clientId,templateId) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

  return fetch(`/checklistDeliverySchedule/exportChecklistDeliverySchedule/${clientId}/${templateId}`, {
    headers: {
        'Content-Type': 'application/json'
    },
    method: 'GET'
}) .then(function (response) {
  if (!response.ok) {
      throw new Error('Unexpected error');
  }

  return response.blob().then(function (blob) {
      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition.split(';')[1].split('=')[1].trim();
      download(blob, filename);
      dispatch({ type: actions.TASK_END, [pendingTask]: end });
    
  });
}).catch(error => {
  return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.TASK_END, null, error);
});
};
}

/**
 * Fetches Checklist Delivery Schedule For a given client & calendar year
 * If successful this will dispatch the CHECKLIST_SCHEDULED_DELIVERY_SUCCESS action,
 * otherwise it will dispatch the CHECKLIST_SCHEDULED_DELIVERY_FAILURE action.
 * @param {number} clientId The id of the Client.
 * @param {number} calendarYear The selected calendar year.
 * @returns {function} A function that returns a Promise.
 */
 export function fetchChecklistDeliverySchedule(clientId, calendarYear) {
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.CHECKLIST_SCHEDULED_DELIVERY_FAILURE,
        checklistDeliverySchedules: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(`/checklistDeliverySchedule/${clientId}/${calendarYear}`)
      .then((response) => {
        return response.json();
      })
      .then((checklistDeliveryScheduleData) => {
        const checklistDeliverySchedules = checklistDeliveryScheduleData
          ? checklistDeliveryScheduleData
          : {};
        if (actionHelpers.isErrorResponse(checklistDeliveryScheduleData)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.CHECKLIST_SCHEDULED_DELIVERY_FAILURE,
            checklistDeliverySchedules
          );
        }

        dispatch({
          type: actions.CHECKLIST_SCHEDULED_DELIVERY_SUCCESS,
          checklistDeliverySchedules,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        const checklistDeliverySchedules = {};
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CHECKLIST_SCHEDULED_DELIVERY_FAILURE,
          checklistDeliverySchedules,
          error
        );
      });
  };
}

/**
 * Upload Checklist Delivery Schedules
 * If failure, it will dispatch the UPLOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} calendarYear The calendar year.
 * @param {file} file The excel file to be used for saving checklist delivery schedules.
 * @returns {function} A function that returns a Promise.
 */
export function uploadChecklistDeliverySchedules(clientId, calendarYear, file) {

    let formData = new FormData();
    formData.append('clientID', clientId);
    formData.append('calendarYear', calendarYear);
    formData.append('file', file);

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
                
        return fetch('/checklistDeliverySchedule/uploadChecklistDeliverySchedules', {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                return response.json();
            })
            .then(response => {
                if (actionHelpers.isErrorResponse(response)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.UPLOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE,
                        response,
                    );
                }
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return response;
            })
            .catch(error => {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.UPLOAD_CHECKLIST_DELIVERY_SCHEDULE_FAILURE,
                    null,
                    error,
                );
            });
    };
}

export function fetchChecklistNavCounts(clientId, checklistId,counterRequest, checklistGuid=null, checklistType=null) {
  const authHeader = checklistGuid ? actionHelpers.buildAuthHeader(checklistGuid, checklistType) : null;
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.CHECKLIST_NAVIGATION_COUNTER_FAILURE,
        checklistCounter: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(`/checklistQuestionnaire/checklistCounter?clientId=${clientId}&checklistID=${checklistId}&GetCountOfAttachments=${counterRequest.GetCountOfAttachments}&GetCountOfDiscrepencies=${counterRequest.GetCountOfDiscrepencies}&GetCountOfComments=${counterRequest.GetCountOfComments}`,
    {
      headers:  (authHeader && new Headers({ Authorization: authHeader })) || null,
      method: 'GET'
    } 
    )
   .then((response) => {
        return response.json();
      })
      .then((checklistCounter) => {
        const checklistCounters = checklistCounter
          ? checklistCounter
          : [];
        if (actionHelpers.isErrorResponse(checklistCounter)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.CHECKLIST_NAVIGATION_COUNTER_FAILURE,
            checklistCounters
          );
        }

        dispatch({
          type: actions.CHECKLIST_NAVIGATION_COUNTER_SUCCESS,
          checklistCounter,
          [pendingTask]: end,
        });
        return checklistCounter;
      })
      
      .catch((error) => {
        const checklistCounters = [];
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.CHECKLIST_NAVIGATION_COUNTER_FAILURE,
          checklistCounters,
          error
        );
      });
  };
}

export function fetchAttachmentDetail(clientId,checklistID,checklistSectionQuestionAttachmentID, checklistGuid=null, checklistType=null) {
  const authHeader = checklistGuid ? actionHelpers.buildAuthHeader(checklistGuid, checklistType) : null;
  return function (dispatch) {
    if (!clientId) {
      return dispatch({
        type: actions.LOAD_ATTACHMENTDETAIL_SUCCESS,
        attachmentDetails: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklistQuestionnaire/attachmentDetail?clientId=${clientId}&checklistID=${checklistID}&checklistSectionQuestionAttachmentID=${checklistSectionQuestionAttachmentID}`,
    {
      headers:  (authHeader && new Headers({ Authorization: authHeader })) || null,
      method: 'GET'
    } 
    )
      .then((response) => {
        return response.json();
      })
      .then((attachmentDetails) => {
        if (actionHelpers.isErrorResponse(attachmentDetails)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_ATTACHMENTDETAIL_FAILURE,
            attachmentDetails
          );
        }
        dispatch({
          type: actions.LOAD_ATTACHMENTDETAIL_SUCCESS,
          attachmentDetails,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_ATTACHMENTDETAIL_FAILURE,
          null,
          error
        );
      });
  };
}

export function deleteAttachment(attachmentId, clientId, checklistGuid=null, checklistType=null) {
  const authHeader = checklistGuid ? actionHelpers.buildAuthHeader(checklistGuid, checklistType) : null;
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/checklistQuestionnaire/deleteChecklistAttachment?clientID=${clientId}&checklistSectionQuestionAttachmentID=${attachmentId}`,
      {
        headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
        method: "DELETE",
      }
    )
    .then(response => {
      return response.text();
  })
      .then((response) => {
        const jsonResponse = response ? JSON.parse(response) : {};      
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_ATTACHMENT_FAILURE,
            jsonResponse
          );
        }

        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.DELETE_ATTACHMENT_FAILURE,
          null,
          error
        );
      });
  };
}



export function deleteComment(commentId, clientId, checklistGuid=null, checklistType=null) {
  const authHeader = checklistGuid ? actionHelpers.buildAuthHeader(checklistGuid, checklistType) : null;
 
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    return fetch(
      `/checklistQuestionnaire/deleteChecklistComment?clientID=${clientId}&checklistSectionQuestionCommentID=${commentId}`,
      {
        headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
        method: "DELETE",
      }
    )
    .then(response => {
      return response.text();
  })
      .then((response) => {
        const jsonResponse = response ? JSON.parse(response) : {};      
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.DELETE_COMMENT_FAILURE,
            jsonResponse
          );
        }

        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.DELETE_COMMENT_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Fetch all the Question comments for a client. If successful this will dispatch the LOAD_QUESTION_COMMENTS_SUCCESS
 * action, otherwise it will dispatch the LOAD_QUESTION_COMMENTS_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
 export function fetchQuestionComments(clientId, checklistId,checklistGuid=null, checklistType=null) {
  const authHeader = checklistGuid ? actionHelpers.buildAuthHeader(checklistGuid, checklistType) : null;
  return function (dispatch) {
    if (!clientId && !checklistId) {
      return dispatch({
        type: actions.LOAD_QUESTION_COMMENTS_SUCCESS,
        questionComments: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklistQuestionnaire/commentsDetail?clientID=${clientId}&checklistID=${checklistId}`,
    {
      headers:  (authHeader && new Headers({ Authorization: authHeader })) || null,
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((questionComments) => {
        if (actionHelpers.isErrorResponse(questionComments)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_QUESTION_COMMENTS_FAILURE,
            questionComments
          );
        }
        dispatch({
          type: actions.LOAD_QUESTION_COMMENTS_SUCCESS,
          questionComments,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_QUESTION_COMMENTS_FAILURE,
          null,
          error
        );
      });
  };
}


/**
 * Fetch all the Checklist Question discrepancies for a client. 
 * If successful this will dispatch the LOAD_QUESTION_DISCREPANCIES_SUCCESS
 * action, otherwise it will dispatch the LOAD_QUESTION_DISCREPANCIES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
 export function fetchChecklistDiscrepancies(clientId, checklistId,checklistGuid=null, checklistType=null) {
  const authHeader = checklistGuid ? actionHelpers.buildAuthHeader(checklistGuid, checklistType) : null;

  return function (dispatch) {
    if (!clientId && !checklistId) {
      return dispatch({
        type: actions.LOAD_QUESTION_DISCREPANCIES_SUCCESS,
        discrepanciesDetail: [],
      });
    }
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklistQuestionnaire/discrepanciesDetail?clientID=${clientId}&checklistID=${checklistId}`,
    {
      headers:  (authHeader && new Headers({ Authorization: authHeader })) || null,
      method: "GET",
    })    
      .then((response) => {
        return response.json();
      })
      .then((discrepanciesDetail) => {
        if (actionHelpers.isErrorResponse(discrepanciesDetail)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_QUESTION_DISCREPANCIES_FAILURE,
            discrepanciesDetail
          );
        }
        dispatch({
          type: actions.LOAD_QUESTION_DISCREPANCIES_SUCCESS,
          discrepanciesDetail,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_QUESTION_DISCREPANCIES_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Allow to update as per report period visibility constraint, this will dispatch the ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_SUCCESS
 * action, otherwise it will dispatch the ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_FAILURE action.
 * @param {number} checklistId The ID of the checklist.
 * @param {string} checklistType The type of the checklist.
 * @param {bool} isExternalTempUser Is external temp user.
 * @param {object} authHeader Auth header
 * @returns {function} A function that returns a Promise.
 */
export function allowUpdateAsPerReportPeriodVisibilityConstraint(checklistId, checklistType, isExternalTempUser, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    let internalUserRequestHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };    
    if(!isExternalTempUser && authHeader){
      internalUserRequestHeaders['Authorization'] = authHeader;
    }

    let externalUserRequestHeaders=null;
    if (isExternalTempUser && authHeader) {
      externalUserRequestHeaders = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',            
        Authorization: authHeader    
      });
    }
 
    return fetch(`/checklists/reportPeriodVisibilityConstraint/${checklistType}/${checklistId}`, {
      headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
    })
      .then(response => {
        return response.json();
      })
      .then(allowUpdateReportPeriodVisibilityConstraintResult => {
        if (actionHelpers.isErrorResponse(allowUpdateReportPeriodVisibilityConstraintResult)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_FAILURE,
            allowUpdateReportPeriodVisibilityConstraintResult,
          );
        }

        dispatch({
          type: actions.ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_SUCCESS,
          allowUpdateReportPeriodVisibilityConstraintResult,
          [pendingTask]: end,
        });
      })
      .catch(error => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.ALLOW_UPDATE__REPORT_PERIOD_VISIBILITY_CONSTRAINT_FAILURE,
          null,
          error,
        );
      });
  };
}

export function saveChecklistComment(request, authHeader) {

  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    let internalUserRequestHeaders = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    
    if(!request.IsExternalTempUser && authHeader){
      internalUserRequestHeaders['Authorization'] = authHeader;
    }

    let externalUserRequestHeaders=null;
    if (request.IsExternalTempUser && authHeader) {
      externalUserRequestHeaders = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',            
        Authorization: authHeader    
      });
    }
 
    return fetch(`/checklistQuestionnaire/saveComment`, {
      headers: request.IsExternalTempUser ? externalUserRequestHeaders : internalUserRequestHeaders ,
      method: 'POST',
      body:JSON.stringify(request)
    })
    .then(response => {
      if (response.ok) {
        return null;
      }
      else {
        return response.json();
      }
    })
      .then(comments => {
        if (actionHelpers.isErrorResponse(comments)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.SAVE_COMMENT_FAILURE,
            comments
          );
        }

        dispatch({
          type: actions.SAVE_COMMENT_SUCCESS,
          [pendingTask]: end
        });

        return comments;
      })
      .catch(error => {
        return actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.SAVE_COMMENT_FAILURE,
          null,
          error,
        );
      });
  };
}

export function insertPsqServiceProviderQuestion(request, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    if (authHeader) {
      headers['Authorization'] = authHeader;
    }

    return fetch(
      `/checklists/addServiceProviderQuestion`,
      {
        headers,
        method: "POST",
        body:JSON.stringify(request)
      }
    )
    .then(response => {
      return response.text();
    })
      .then((response) => {
        const jsonResponse = response ? JSON.parse(response) : {};      
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.INSERT_SERVICE_PROVIDER_QUESTION_FAILURE,
            jsonResponse
          );
        }

        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.INSERT_SERVICE_PROVIDER_QUESTION_FAILURE,
          null,
          error
        );
      });
  };
}

export function removePsqServiceProviderQuestion(request, authHeader) {
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    if (authHeader) {
      headers['Authorization'] = authHeader;
    }

    return fetch(
      `/checklists/removeServiceProviderQuestion`,
      {
        headers,
        method: "DELETE",
        body:JSON.stringify(request)
      }
    )
    .then(response => {
      return response.text();
    })
      .then((response) => {
        const jsonResponse = response ? JSON.parse(response) : {};      
        if (actionHelpers.isErrorResponse(jsonResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.REMOVE_SERVICE_PROVIDER_QUESTION_FAILURE,
            jsonResponse
          );
        }

        dispatch({ type: actions.TASK_END, [pendingTask]: end });
        return jsonResponse;
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.REMOVE_SERVICE_PROVIDER_QUESTION_FAILURE,
          null,
          error
        );
      });
  };
}

/**
 * Fetch last service provider question id. 
 * If successful this will dispatch the LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_SUCCESS
 * action, otherwise it will dispatch the LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_FAILURE action.
 * @param {object} authHeader for external users
 * @returns {function} A function that returns a Promise.
 */
 export function fetchLastServiceProviderQuestionId(authHeader) {
  
  return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/checklists/lastServiceProviderQuestionId`,
    {
      headers:  (authHeader && new Headers({ Authorization: authHeader })) || null,
      method: "GET",
    })    
      .then((response) => {
        return response.json();
      })
      .then((lastServiceProviderQuestionId) => {
        if (actionHelpers.isErrorResponse(lastServiceProviderQuestionId)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_FAILURE,
            lastServiceProviderQuestionId
          );
        }
        dispatch({
          type: actions.LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_SUCCESS, //LOAD_QUESTION_DISCREPANCIES_SUCCESS,
          lastServiceProviderQuestionId,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_LAST_SERVICE_PROVIDER_QUESTION_ID_FAILURE,
          null,
          error
        );
      });
  };
}
